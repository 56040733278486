.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trophy-button {
  height: 1.5rem;
  width: 1.5rem;
}

.trophy-button-sidebar {
  height: 1rem;
  width: 1rem;

}

.splash-top {
  padding-left: 0!important;
}

.splash-top-button {
  padding-right: 0!important;
}

.join-challenge-button {
  z-index: 999!important;
  position: absolute;
  top: 2%;
  right: 2%;
  text-align:center;
}

.join-challenge-card {
  z-index: 999!important;
  position: absolute;
  top: 2%!important;
  right: 2%!important;
  text-align:center;
}

.join-challenge-splash {
  z-index: 999!important;
  position: absolute;
  top: 2%!important;
  right: 7%!important;
  text-align:center;
}

.challenge-image-container {
  position: relative;
}

.title-container {
  font-size: 5rem;
  overflow: hidden;
}

.tab-list-container {
  list-style-type: none;
  padding-left: 0px;
}

.tab-list-li {
  float: left;
}

.headline-number-container{
  position: relative;
}

.headline-number-units {
  font-size: 1rem;
}

.icon-container {
  white-space: nowrap;
}

.icon-tab {
  display: inline-block;
}

.tab-sub-text {
  font-size: 0.6rem!important;
  margin-top: 0.2rem;
  text-align: center;
  font-weight: normal;
}

.tab-image-sidebar {
  height:1.75rem;
  width:1.75rem;
  margin: 0 auto;
}

.image-fine-print {
  width: 3.5vh;
  height: 3.5vh;
}

.registered {
  box-shadow: 0 0 0.4vh 0.4vh #C0E8F9;
}

.headline-challenge-image {
	height: 45vh;
}

.headline-challenge-description-box {
	position:relative;
}

.more-challenges-box {

}

.headline-challenge-btn-box {
	position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
  .sticky-column-activities {
    /* display: none; */
  }

  .participants-actual-text {
  	display: none;
  }
}

.challenge-sidebar-list {
  padding-top: 0rem!important;
  padding-bottom: 0rem!important;
  padding-left: 0.75rem!important;
  padding-right: 0.75rem!important;
}

.spinner-container {
  margin: 0 auto;
}

.scroll-down {
  margin-top: -6px;
  height: auto;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.parent {
  position: relative;
}

.description-box {
  display: flex;
}

.done {
  background: red;
}



/* SPLASH PAGE */

.splash-title-box {

}

.splash-more-info-big-box {

}

.splash-more-info-image-box {
  position: relative;
}

.splash-more-info-description-box {
  font-size: 20px;
}

.splash-bottom {

}

.splash-status {

}

.splash-button {
  text-align: center;
  position: absolute;
  right: 2%;
  top: 4%;
}

.splash-header-image {
  width: 100%;
  height: 100%;
}

.featured-challenge-mobile {
    display: none;
}

.featured-challenge-desktop {
  display: block;
}

@media only screen and (max-width: 600px) {
  .featured-challenge-desktop {
    display: none;
  }

  .featured-challenge-mobile {
    display: block;
  }
}

.show-hide-button {
  margin-top: 0!important;
  padding-top: 0!important;
}

.title-fine-print {
  overflow: hidden;
}

.truncate-container {
  overflow: hidden;
}

.challenge-sidebar-list-container {
  padding-inline-start: 0px;
  margin-block-end: 0.5rem!important;
}


.challenge-leaderboard-image-container {
  height: 2rem;
  width: 2rem;
}

.challenge-leaderboard-image {
  height: 100%;
  width: 100%;
  border-radius: 50%
}

.challenge-leaderboard-ranking {
  color: black;
  position: absolute;
  top: 20%;
  right: 1%;
  width: 2rem;
  height: 2rem;
  height: auto;

}

.challenge-leaderboard-medal {
  width: 100%;
  height: 100%;
}

.challenge-leaderboard-card {
  border-radius: 0px!important;
}

.challenge-leaderboard-col-left {
  padding-right: 5px!important;
}

.challenge-leaderboard-col-right {
  padding-left: 0!important
}

.nav-link.active {
  background-color: #28A745!important3
}

/*.fine-print-description-truncate {
  margin-bottom: 0.2rem!important;
}*/
