.red .active a,
.red .active a:hover {
    background-color: red !important;
}

.nav > .active > a, .nav-tabs > .active > a:hover {
    background-color: red !important;
}

a.nav-link, a.nav-link:visited, a.nav-link:focus, a.nav-link:hover, a.nav-link:active, 
.panel-text a, .panel-text a:visited, .panel-text a:focus, .panel-text a:hover, .panel-text a:active,
.panel-title a, .panel-title a:visited, .panel-title a:focus, .panel-title a:hover, .panel-title a:active,
a.panel-title /* << keep this last one too! */
{
  color: #000000; font-weight: bold; text-decoration: none!important;
}

.badge {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  padding: 3px 6px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 99999px
}

.badge.badge-success {
  background-color: #28a745;
}

.badge.badge-outlined {
  background-color: transparent
}

.badge.badge-outlined.badge-success {
  border-color: #28a745;
  color: #28a745;
}
