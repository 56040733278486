.channel-image {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.channel-item-title{
	font-size:1.5em;
	text-decoration: none !important;
	margin-bottom:2px;
}

a.channel-item-title {color: #333;}
a.channel-item-title:hover {color: #aaa;}

.channel-item-info{
	font-size:0.9em;
	color: #333;
	margin-bottom:5px;
}

/*.channel-description{
	width: 100%;
	text-align: center;
	font-size: 20px;
}

.channel-edit-button{
	float: right;
	color: gray;
	font-size: 30px;
}
*/
.add-video-icon{
	float: left;
}

.form-group .channel-title-input{
  display: block;
	text-align: left;
	width: 100%;
  resize: none;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.form-group .channel-description-input{
	display: block;
	text-align: left;
	width: 100%;
  min-height: 80px;
	resize: yes;
	border: 1px solid #ced4da;
	border-radius: .25rem;
}

.channel-first-video{
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  cursor: default;
}


.channel-first-video img {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom:20px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(0px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}


.channel-thumb {
  object-fit: cover;
  float: left;
  height:225px;
  width:338px;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.channel-thumb .overlay {
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
}

.channel-thumb img {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom:20px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(0px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}


.productImage {
    position: relative;
    float: left;
    display: inline-block;
}
.productImage {
    width: 100%;
}
.productImage:after {
    padding-top: 70%;
    display: block;
    content:'';
}
.img-wrapper {
    padding: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.img-wrapper .thumb {
    height:100%;
}
.img-project, .thumb {
    display: block;

}
.img-project img, .thumb img {
    position:relative;
    top:50%;
    left:50%;
    max-width:100%;
    max-height:100%;
    -webkit-transform:translate(-50%,-50%);
    -moz-transform:translate(-50%,-50%);
    -ms-transform:translate(-50%,-50%);
    -o-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.productDetails {
}
/************************************************
Media queries
************************************************/
 @media all and (max-width: 1024px) {
    .img-project, .thumb {
        padding: 7px;
    }
}


