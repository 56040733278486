/* RESPONSIVE TYPOGRAPHY (combine/reconcile these) */

/* start 1 */

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 100%;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }
}

/* end 1 */

/* start 2 */

.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head { font-size:8.75vw !important }
.section-subhead { font-size:.75rem }
.showcase-row { padding: 1.5rem 2rem 3rem !important; }
.pricing-card { width: 290px }
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 10vw; line-height: 10vw; font-weight: 600; }
/* .page-subheadingX { font-size: 5vw; } */

@media (min-width:375px){
  .pricing-card {width: 345px }
}

@media (min-width:485px){
.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head {font-size:9vw !important}
.section-subhead {font-size:.75rem}
.showcase-row {padding: 1.5rem 3rem 3rem !important;}
.pricing-card {width: 340px }
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 10vw; line-height: 10vw }
}
@media (min-width:576px){
.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head {font-size:7vw !important}
.section-subhead {font-size:.75rem}
.showcase-row {padding: 1.5rem 3rem 3rem !important;}
.pricing-card {width: 340px }
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 9vw; line-height: 9vw }
}
@media (min-width:768px){
.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head {font-size:4.1vw !important}
.section-subhead {font-size:1rem}
.showcase-row {padding: 1rem 3rem 2rem !important;}
.pricing-card {width: 350px }
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 8vw; line-height: 8vw }
}
@media (min-width:992px){
.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head {font-size:4.5vw !important}
.section-subhead {font-size:1rem}
.showcase-row {padding: 1rem 3rem 1rem !important;}
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 6.5vw; line-height: 6.5vw }
}
@media (min-width:1280px){
.hero-heading { font-size: 6vw; }
.hero-subheading { font-size: 3vw; }
.down {display: none; }
.section-head {font-size:4.5vw !important}
.section-subhead {font-size:1.5rem}
.showcase-row {padding: 1rem 3rem 1rem !important;}
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 5vw; line-height: 5vw }
}

.badge-new {
font-size: 1.5vh!important;
vertical-align: top;
position: relative;
border-radius: .25rem;
}

/* .more {
  position: absolute;
  z-index: 100000;
}

.content {
  position: relative;
} */


/* end 2 */


/* start 3 */

.logo-main{
  width:250px;
  cursor: pointer;
}

@media only screen and (max-width: 320px) {
  .logo-main{
    width:260px;
  }
  .main-title{
    font-size:1.3em;
  }
  .main-image{
    width:280px;
    display: inline-block;
  }
   .main-image-lg{
     display: none;
   }
  .main-text{
    width:280px;
  }
}

@media only screen and (min-width: 550px) {

  .logo-main{
    width:380px;
  }
  .main-title{
    font-size:2.0em;
  }
  .main-image-lg{
    width:800px;
    display: inline-block;
  }
  .main-text{
    width:500px;
  }
 .main-image{
   display: none;
 }
}

@media only screen and (min-width: 550px) {
  .main-image-lg{
    width:500px;
    display: inline-block;
  }
}

@media only screen and (min-width: 990px) {
  .logo-main{
    width:380px;
  }
  .main-title{
    font-size:2.0em;
  }
  .main-image-lg{
    width:800px;
    display: inline-block;
  }
  .main-text{
    width:780px;
    font-size:1.4em;
  }
 .main-image{
   display: none;
 }
}

/* end 3 */



/* start 4 */

.font-size-mlg{
  font-weight:600;
  font-size:2.5em;
}

.font-size-xlg{
  font-size:1.5em;
  font-weight: bold;
  width: 100%;
}

/* end 4 */

/* END responsive type section */

.add-bottom-margin{
  margin-bottom: 20px;
}

.activity-panel{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: normal;
  background-color: #f6f6f6;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  width:100%;
  margin-bottom: 10px
}

.activity-title{
  font-size:1.1em;
  font-weight:700;
}

.activity-date{
  font-size:0.8em;
  font-weight:400;
}

.activity-link{
  font-size:1.2em;
  font-weight:700;
}

.activity-header{
  font-size:0.7em;
}

.activity-value{
  font-size:1em;
  font-weight:400;
  padding-left: 15px!important;
  padding-right: 10px!important;
  word-wrap: normal;
}

.dropdown-menu-center {
    left: -50px !important;
}

.learn-more{
    margin-bottom: 15px;
  }

.list-inline-item.blank-li, .list-inline-item.try-our-software{
    display: none;
  }

/* END */

body {
  margin: 0;
  padding: 0;
  padding-top: 55px;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.5;
}



#repeat-x { background-repeat: repeat-x; }

/* body {
  background: url(/bg-nav.png);
} */

a.nav-link.nav-vf {
  display: ;
  padding: 0rem;
}

.nav-color, .footer-color {
  background: rgb(10,29,63);
  background: linear-gradient(270deg, rgba(10,29,63,0.7763480392156863) 30%, rgba(196,44,55,1) 100%);
  /* background: linear-gradient(90deg, rgba(10,29,63,1) 0%, rgba(196,44,55,1) 100%); */
}

.navbar-bottom {
background: rgb(10,29,63);
background: linear-gradient(90deg, rgba(10,29,63,1) 0%, rgba(196,44,55,1) 100%);
}

.navbar {
  padding: 0rem!important;
  padding-left: 3rem!important;
}

.dropup .dropdown-menu {
  top: auto;
  margin-bottom: .125rem;
}

.navbar-bottom {
    height: 55px;
    padding: 0rem!important;
    padding-left: 0rem!important;
}

.navbar-trans {
  background: transparent; }


.navbar-toggler {
  padding: .25rem 0!important;
}

.navbar-toggle.collapsed {
  background-color: #000000!important;
}

#navbarDropdownMenuLink, 
  #vidracing-navbar-mobile > ul > li.nav-item.dropdown.pt-2.show > div > a, 
  #vidracing-navbar-mobile > ul > li > a, 
  #vidracing-navbar-mobile > ul > li.nav-item.dropdown.pt-2.pr-2.pr-md-5.show > div > div > a,
  #root > div > div > nav.navbar.navbar-expand.navbar-default.navbar-dark.fixed-bottom.d-flex.justify-content-center.nav-color.navbar-bottom > div > ul > li > a
  {
  font-size: .95rem;
  }

#vidracing-navbar-mobile > ul > li > a, 
  #navbarDropdownMenuLink, 
  #root > div > div > nav.navbar.navbar-expand.navbar-default.navbar-dark.fixed-bottom.d-flex.justify-content-center.navbar-bottom > div > ul > li > a,
  #vidracing-navbar-mobile > ul > div > li > a
 {
  color: #ffffff;
}

.dropdown {
  margin-left: 0px!important;
}

a.dropdown-toggle.download-nav::after {
  display: none;
}

dropdown-toggle .dropdown-profile::after {
    content: none!important; 
    display:none!important; 
    border-top: 0rem!important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
background-color: #ffffff!important;
border-color: #cccccc;
border-style: solid;
border-width: 1px;
}

.navbar-main a.nav-link {
  font-weight: normal!important;
}

.video-page-tabs .nav-link, .video-page-tabs .nav-link:hover, .video-page-tabs .nav-link.active {
  font-weight: normal!important;
}
 
.page-item.active .page-link {
  background-color: #333333!important;
  border-color: #333333!important;
}

.page-link:hover {
  /*color: #cccccc!important;
  background-color: #cccccc!important;*/
}

.challenges-detail-link:hover {
  text-decoration: underline!important;
}

a.help-link:hover {
  text-decoration: underline!important;
}

/* used in activities placeholder only (Bootstrap class instead?) */
.img-cover-fit {
  object-fit: cover;
}
/* end */

h3.page-title, h3.sidebar-title, h3.sidebar-title-profilename, h3.section-subhead {
  font-weight: 600;
  font-size: 175%;
}

.event-list {
    padding: 0;
    list-style-type: none;
}

/* start radio toolbar - unused */

.radio-toolbar {
  margin: 10px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
    display: inline-block;
    background-color: transparent;
    color: #000000;
    padding: 10px 20px;
    border: 1px solid #0A1D3F;
    border-radius: 4px;
}

.radio-toolbar label:hover {
  background-color: #cccccc;
  border: 1px solid #0A1D3F;
}

.radio-toolbar input[type="radio"]:focus + label {
    border: 1px solid #0A1D3F;
}

.radio-toolbar input[type="radio"]:checked + label {
    background-color: #0A1D3F;
    color: #ffffff;
    border: 1px solid #0A1D3F;
}

/* end */

.footer {
  color: #000000; /*text color*/
  background: #ffffff;
}

a.footer-link, a:link.footer-link, a:visited.footer-link {
  color: #000000;
}

a:hover.footer-link, a:active.footer-link {
  color: #000000;
}

.card-footer, .card-footer a:link, .card-footer a:visited {
  color: #000000!important;
  text-decoration: none!important;
  }

.card-footer {
  font-size: 95%;
  line-height: 1.1em;
  border-bottom: none;
  }

.card-footer a:hover {
  color: #000000!important;
  text-decoration: none!important;
  }

.card-footer-subtext {
  font-size: 80%;
  }

.card-footer-subtext, .card-footer-subtext a:link, .card-footer-subtext a:visited, .card-footer-subtext a:active {
  color: #696969;
  }

.feature-see-all, .feature-see-all a:link, .feature-see-all a:visited, .feature-see-all a:hover, .feature-see-all a:active
  {
  color: #000000!important;
/*  color: #28a745!important; */
  font-size: 1rem;
  font-weight: normal;
  display: inline-block;
  }

  .feature-see-all a:hover {
    text-decoration: underline; 
  }

.feature-row-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  }

.feature-row-title {
  font-size: 175%;
  font-weight: 600;
  display: flex;
  align-items: center;
  }

.img-zoom {
  transition: transform .15s;
  }

.img-zoom:hover {
  transform: scale(1.05); /* (150% zoom)*/
  transform-origin: center;
  transition: transform 1s;
  }

.gallery-card {
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;
  }

.gallery-image {
  top: 0px;
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;
  }

.gallery-card-hero {
  width: 100%;
  top: 0px;
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;
  }

.gallery-image-hero {
  width: 100%;
  top: 0px;
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;
  }

.time-indicator {
  color: #ffffff;
  background: transparent;
  font-weight: bold;
  position: absolute;
  top: 1.5rem;
  }

/* combine these two in pages later. */
.gradient-icon, .gradient-indicator {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  }

/* .fa-angle-left { font-size: 3rem; position: absolute; left:5rem;
  }

.fa-angle-right { font-size: 3rem; position: absolute; right:5rem;
  }
 */
 
.showcase {
  align-items: center
}

/* used in audio overlay and pricing */

.custom-switch.custom-switch-sm .custom-control-label {
    padding-left: 1rem;
    padding-bottom: 1rem;
    color: #28a745;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
    color: #28a745;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    border-radius: calc(1rem - (1rem / 2));
    color: #28a745;
}

.custom-switch.custom-switch-sm .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(1rem - 0.25rem));
    color: #28a745;
}

/* end */

.event-status {
  z-index: 99!important;
  position: absolute;
  top: 9%!important;
  right: 6%!important;
  text-align:center;
}

.event-status-real {
  z-index: 99!important;
  position: absolute;
  top: 9%!important;
  left: 6%!important;
  text-align:center;
}

/* ASPECT RATIO FOR VARIOUS GALLERY VIEWS */
  /* With no other attributes set on this class, the "relative" value is not really changing the position of the div that has this class, but it allows its child elements to be positioned absolutely relative to their parent. */

.aspect16x9 {
  position: relative;
  }

/* Create a pseudo element that uses padding-bottom to take up space to create a 16:9 aspect ratio box. */

.aspect16x9::after {
  display: block;
  content: '';
  /* 16:9 aspect ratio */
  padding-bottom: 56.25%;
  }

/* Image is positioned absolutely relative to the parent element. */
.aspect16x9 img {
  /* Image should match parent box size. */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* Rounded (move this to separate class, as already used above): */
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;

  }

/* BELOW IS USED FOR OVERLAY BUT DETERMINE HOW IT INTERACTS WITH SIBLING CLASS ASPECTRATIO16X9 THEN SIMPLY IF NEEDED. */

/* Parent Container */
/*.videobox-UNUSED {
 position: relative;
 width: 98%;
 height: 98%;
 float: left;
 margin-right: 10px;
}*/

/* Child Text Container */
.videobox div{
 position: absolute;
 top: 0;
 left: 0;
 background: white;
 color: black;
 margin-bottom: 5px;
 font-family: sans-serif;
 opacity: 0;
 visibility: hidden;
 -webkit-transition: visibility 0s, opacity 0.5s linear;
 transition: visibility 0s, opacity 0.5s linear;
}

/* Hover on Parent Container */
.videobox:hover{
 cursor: pointer;
}

.videobox:hover div{
 width: 100%;
 height: 100%;
 visibility: hidden;
 opacity: 0.7;
}

/* END */


/* ====================================================

    Review classes below to see:
      1) which are no longer being used, and 
      2) which can be accomplished by standard bootstrap classes (or customized standard classes)
*/

button:focus {
  outline: 0;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.add-video-icon{
  margin-left: 20px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.login-panel-container{
  height: 100vh; 
}

.main-image{
  width:300px;
}

.mail-title{
  /*font-size:1.0em; */
}

.main-text{
  width:300px;
  font-size:1.1em;
}

.main-image-lg{
  width:500px;
  display: none;
}

.screen-shot{
  width:100%;
}

.setup-bullet{
  width:60px;
}

.nav-red{
  background-color: #F91C3D;
  font-size:1.2em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.terms-and-services{
  width:90%;
}

.container-centered {
    height: 100%;
    /* display: flex;
    justify-content: center; */
    align-items: center;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.login-title {
  font-size:32px;
  font-weight: 500;
  margin-bottom: 30px;
}

.login-panel {
  height:600px;
}

.terms-and-services-panel{

}

.terms-and-services-panel-2{
  font-size: 14px;
  position: relative;
  /* left: 27px; */
  top: 10px;
}

/* various links */ 

.terms-and-services-panel a, .terms-and-services-panel a:link, .terms-and-services-panel a:visited, .terms-and-services-panel a:hover, .terms-and-services-panel a:active,
.terms-and-services-panel-2 a, .terms-and-services-panel-2 a:link, .terms-and-services-panel-2 a:visited, .terms-and-services-panel-2 a:hover, .terms-and-services-panel-2 a:active,
.forgot-password a, .forgot-password a:link, .forgot-password a:visited, .forgot-password a:hover, .forgot-password a:active {
  color: black;
}

.terms-and-services-panel a:hover, .terms-and-services-panel2 a:hover, .forgot-password a:hover {
  text-decoration: underline;
}

.feature-row-title a:hover { text-decoration: none; }

.font-size-lg{
  font-size:1.4em;
}

.m-r-1 {
  margin-right: 0.5em;
}

.m-t-1 {
  margin-top: 0.5em;
}

.m-t-2 {
  margin-top: 1.0em;
}

.m-t-3 {
  margin-top: 1.5em;
}

.m-b-1 {
  margin-bottom: 0.5em;
}

.m-b-2 {
  margin-bottom: 1.0em;
}

/* .m-b-3 {
  margin-bottom: 1.5em;
} */

.facebook_button {
  position: relative;
  padding: 10px 47px;
  text-align: center;
  width: 94px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  height: 44px;
  margin-bottom:15px;
}

.facebook-icon{
  position: absolute;
  left: 24px;
  font-size: 34px;
  bottom: 8px;
}

.sign-in-button .envelope-icon{
  color:black;
  width: 31px;
  height: 80px;
  position: absolute;
  top: -15px;
  left: 16px;
}

.trash-icon{
  color: #C0C0C0;
  font-size: 25px;
  vertical-align: middle;
}

.google_logo{
  position: absolute;
  left: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 35px;
}

.sign-in-button {
  /* margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 318px; */
  display: block;
  font-weight: bold;
  height: 52px;
  background-size: 24px;
  background-position: 16px center;
  background-repeat: no-repeat;
  font-size: 16px;
  position: relative;
  text-align: center;
  border: 1px solid #ccccd1;
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 52px;
  padding-right: 8px;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 1.42857143;
  cursor:pointer;
} 

.facebook-sign-in-button{
  color: #fff !important;
  background-color: #3b5998;
  border-color: #3b5998;
}

/* .google-sign-in-button{
  left:20px;
  color: #2d2d32;
  background-color: #fff;
  border: 1px solid #ccccd1;
} */

.in-touch{
  text-align: center;
  font-size: 30px
}

.not-authorized-jumbotron{
  margin-top: 150px;
  text-align: center;
}

.email-verified-jumbotron{
  margin-top: 25px;
  text-align: center;
}

.wrench-icon{
  font-size: 100px;
  color: #FF5722;
}

.vidRacer-hr{
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid black;
  margin: 1em 0; padding: 0;
}

.vidRacer-hr-gray{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid gray;
  margin: 0.5em 0 1em 0; padding: 0;
}

/* Profile Page */

#profile-form .profile-label{
  padding-right: 1px;
  text-align: right;
  font-weight:600;
}

#profile-form .form-group.row:first-of-type{
  border-top: 1px solid #e6e6eb;
}

#profile-form .form-control.narrow, #profile-form .form-control-plaintext.narrow{
  width: 70%;
  display: inline;
  padding-right: 0px;
}

#profile-form .grayed-row {
  border-bottom: 1px solid #e6e6eb;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #f5f5fa;
}

#profile-form .row {
  border-bottom: 1px solid #e6e6eb;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 0px;
}

#profile-form .unit{
  font-weight: bold;
  padding-left: 7px;
}

.electron-switch{
  margin-left: 15px;
}

.electron-switch > span{
  margin-right: 5px;
  top:5px;
}

.electron-switch > div{
  top: 10px;
}

.successfully-logged-in{
  font-size: 24px;
  font-weight: bold;
}

.privacy-page{
  margin-top: 50px;
}

.privacy-page h3, .privacy-page h4{
  margin-bottom: 25px;
}

.moveUp {
  position: relative;
  bottom: 25px;
}

.forgot-password{
  margin-bottom: 20px;
}

#general-message{
  color: black;
}

#general-error{
  color: black;
  margin-bottom: 15px;
  margin-top: 15px;
}

ul.pagination{
  justify-content: center;
/*  color: #218838; */
}

.back-btn{
  margin-right: 10px;
}

/* ==================================================== */
 
.center-align {
  text-align: center;
}

/*
    Determine if .center-align class currently used in the following pages can be replaced with standard bootstrap:
      login,
      login-email,
      login-success,
      cancellation-success,
      password-recovery,
      password-recovery-second-step,
      payment-sucess (misspelled page),
      portal-redirect,
      privacy-text,
      sign-up,
      terms-and-services,
      terms-and-services-new,
      terms,
      text,
      upgrade,
      usa-cycling,
*/

/* ====================================================

    Remove the following classes if indeed they are no longer referenced anywhere (no sign of them in frontend files):
*/

/*

.reverse-contrast {
  background-color: #ffffff;
  }

.section-head {
  font-size: 2rem;
  font-weight: bold;
}

.logo-nav {
  height:50px;
  margin-bottom:7px;
}

.profile-image img {
  border-radius: 50%;
  width: 45px;
}

.content-section-a {
  padding: 30px 0px 30px 0px;
  margin-top: 30px;
  background-color: #f8f8f8;
}

.content-section-b {
  padding: 30px 0;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.no-padding {
  padding: 1rem 1rem !important;
}

.min-height-instructions {
  min-height: 90px;
}

.footer-copyright {
  margin-top: 50px;
  margin-bottom: 0px;
}

.footer-copyright .content-section-a {
  margin-bottom: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.footer-copyright .content-section-b {
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

*/
