.hero-video-container {
  position: relative;
}

.hero-video-container video {
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.hero-video-overlay {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 8vh;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.btn-gradient, .btn-gradient:hover, .btn-gradient:focus {
  /* background: linear-gradient(90deg, rgba(10,29,63,1) 0%, rgba(196,44,55,1) 100%); */
  /* background: rgba(10,29,63,1)!important; 
  border-color: white!important; */
}

/* 
@include media-breakpoint-up(sm) {
  .text-responsive {
    font-size: calc(150% + 1.2vw + 1.25vh);
  }
}

@include media-breakpoint-up(md) {
  .text-responsive {
    font-size: calc(200% + 1.2vw + 1.25vh);
  }
}

@include media-breakpoint-up(lg) {
  .text-responsive {
    font-size: calc(250% + 1.2vw + 1.25vh);
  }
} */

/* .xtext-responsive {
  font-size: calc(150% + 1.2vw + 1.25vh);
}

.xtext-responsive-2 {
  font-size: calc(90% + .9vw + .9vh);
}

/* .text-responsive {
  font-size: 36px;
}
.text-responsive-subhead {
  font-size: 18px;
}
@media screen and (min-width: 320px) {
  .text-responsive {
    font-size: calc(36px + 6 * ((.50vw - 320px) / 680));
  }
  .text-responsive-subhead {
    font-size: calc(18px + 6 * ((.50vw - 320px) / 680));
  }
}
 @media screen and (min-width: 1000px) {
  .text-responsive {
      font-size: 108px;
    }
  .text-responsive-subhead {
    font-size: 48px;
  }
}  */

.portraitview {
    margin-top: -130px;
    display: inline!important;
    }

.hero-home-textbox h1 {
    font-size: 175%;
    font-weight: bold;
    }
.hero-home-textbox h2 {
    font-size: 120%;
    }
.hero-home-textbox h1 {
    padding-top: 100%;
    }

@media (min-width: 300px) {
      .portraitview {
        margin-top: -120px;
        } 
      .hero-home-textbox h1 {
          font-size: 180%;
          font-weight: bold;
          }
      .hero-home-textbox h2 {
          font-size: 115%;
          }
      .hero-home-textbox h1 {
          padding-top: 80%;
          }
      }

@media (min-width: 450px) {
        .portraitview {
          margin-top: -105px;
          } 
        .hero-home-textbox h1 {
          font-size: 250%;
          font-weight: bold;
          }
        .hero-home-textbox h2 {
          font-size: 130%;
          }
        .hero-home-textbox h1 {
          padding-top: 70%;
          }  
        }
  
@media (min-width: 660px) {
  .portraitview {
    margin-top: -65px;
    } 
  html {
    font-size: 110%;
    }
  .hero-home-textbox h1 {
    font-size: 350%;
    }
  .hero-home-textbox h2 {
    font-size: 142%;
    }
  .hero-home-textbox h1 {
    padding-top: 50%;
    }
  }

@media (min-width: 800px) {
  .portraitview {
    margin-top: -45px;
    } 
  html {
    font-size: 120%;
    }
  .hero-home-textbox h1 {
    font-size: 425%;
    }
  .hero-home-textbox h2 {
    font-size: 190%;
    }
  .hero-home-textbox h1 {
    padding-top: 75%;
    }
  }

@media (min-width: 950px) {
    .portraitview {
      margin-top: 15px;
      } 
    }

@media (min-width: 1200px) {
  .portraitview {
    margin-top: 10px;
    } 
  html {
    font-size: 140%;
    }
  .hero-home-textbox h1 {
    font-size: 525%;
    }
  .hero-home-textbox h2 {
    font-size: 225%;
    }
  .hero-home-textbox h1 {
    padding-top: 75%;
    }
  }

@media (min-width: 1300px) {
    .portraitview {
      margin-top: 20px;
      } 
  }

.hero-home-textbox h1 {
  white-space: nowrap;
}
.hero-home-textbox h2 {
  line-height: 50%;
  white-space: nowrap;
}

.hero-home-textbox {
  position: relative;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
}

.hero-container {
  position: relative;
  background-color: white; /* #031E41*/
  max-height: 65vh;
  /* min-height: 25rem; */
  width: 100%;
  overflow: hidden;
}

.hero-container .container {
  position: relative;
  z-index: 2;
}

.hero-container video {
  position: absolute;
  top: 39%;
  left: 50%;
  width: 100%; /*added*/
  /* min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto; */
  background: #031E41; /*added*/
  opacity: 0.9;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.hero-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  /* background: linear-gradient(270deg, rgba(10,29,63,0.7763480392156863) 30%, rgba(196,44,55,1) 100%); */
  /*background: linear-gradient(0deg,hsla(0,0%,96.9%,.6334908963585435) 14%,#5b3183 89%);
  background: linear-gradient(0deg, rgba(247,247,247,0.6334908963585435) 14%, rgba(91,49,131,1) 89%); */
  /* background: #031E41; */
  /* opacity: 0.1; */
  z-index: 3; /* was 1 */
}

.usac-promo
 {
  background-color: #031E41!important;
  color: #ffffff;
  }

.usac-promo h5 {
 font-size: 4.5vw;
}

/* where are these used? */

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
