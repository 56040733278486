/* VIDEO PAGE */

.overlay-image-watched {
  width: 2.5rem!important;
  height: 2.5rem!important;
  z-index: 999999999!important;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-40%, -40%);
}

.line-clamp-5 {
  /* Added by Vee. */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-clamp-2 {
  /* Added by Vee. */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}


.video-container{
  position: relative;
  background-color: #000;
  height: 675px;
  width: 100%;
  padding: 0;
  margin: 0;
}


.video-container video{
  max-width: 100%;
  height: 675px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
}

.not-theater-mode{
  height:50vw !important;
}

.tabber{
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
    z-index: 999999;
}

.video-time{
  margin: 0px 15px 0px 0;
}

.tracker-slope{
  margin: 0px 15px 0 0;
}

.tracker-slope-gradient {
  
  height: 25px;
  width: 25px;
  margin: 7.5px 15px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;


}

.disable {
  filter: invert(0.5);
}

.tracker-slope-image {
  width: 100%;
  height: 100%;
  filter: invert(1);
}

video.full-width-video-container {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  background: black;
}

.theater-video-container{
  position: relative;
  height: 100vh;

  /*-webkit-transition: width 2s, height 4s; /* For Safari 3.1 to 6.0 */
  /*transition: width 1s, height 1s;*/
}

.theater-video-container video{
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.title a{
  font-size:30px;
  text-decoration: none;
}

.form-error{
  color:red;
}

.form-label{
  color: #F91C3D;
}

.form-label{
  display: block;
}

.form-label .permalink-unique{
  color: lightgreen;
  margin-left: 10px;
  font-size: 20px;
}


.edit-button{
  color: gray;
  margin-left: 10px;
  font-size: 30px;
  float: right;
  margin-bottom: 15px;
/*  margin-top: 15px;*/
}

.delete-button{
  color: gray;
  margin-left: 10px;
  font-size: 30px;
  float: right;
  margin-bottom: 15px;
/*  margin-top: 15px;*/
}

.username{
  margin-top:10px;
  font-size:14px;
  text-align: center;
}

.chrome-extension-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.record-buttons{
  display: flex;
  height: 45px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 15%;
  font-size: 30px;
  /*color: #FFFFFF;*/
  font-weight: bold;
  margin-left: 2%;
  text-align: center;
  min-width: 125px;
  width: 125px;
  font-family: Helvetica, Arial, sans-serif;
  text-shadow: black 1px 0px 0px;
  z-index: 999999;
  opacity: 1;
  color: #e55;
}

.gauges{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  top: 5%;
  font-size: 30px;
  /*color: #FFFFFF;*/
  font-weight: bold;
  margin-left: 2%;
  text-align: center;
  min-width: 125px;
  width: 125px;
  font-family: Helvetica, Arial, sans-serif;
  text-shadow: black 1px 0px 0px;
  z-index: 999999;
  opacity: 1;
  color: white;
}

.tracker-wrapper{
  position: relative;
  top: -17px;
}

.tracker{
  display: flex;
  height: 45px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 5%;
  font-size: 30px;
  font-weight: bold;
  margin-left: 2%;
  text-align: center;
  min-width: 20%;
  width: 96%;
  font-family: Helvetica, Arial, sans-serif;
  text-shadow: black 1px 0px 0px;
  z-index: 100;
  opacity: 1;
  color: white;
  white-space: nowrap;
}

.leaderboard{
  /*starts hidden*/
  border-radius: 6px;
  margin:0px;
  padding: 2px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  top: 2%;
  right: 1%;
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 600;
  text-align: center;
  width: 311px;
  font-family: Helvetica, Arial, sans-serif;
  /*text-shadow: black 1px 0px 0px;*/
  z-index: 9;
  opacity: 1;
}

.tracker-volume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px
}

.tracker .tracker-controls{
  flex: 0.1;
  display: flex;
  justify-content: flex-start;
  color: white;
}

.control-icon{
  padding-right: 20px;
}

.tracker .control-icon.disabled, .record-buttons .control-icon.disabled{
  color: #d3d3d3;
  cursor: default ;
}

.tracker .control-icon.disabled svg, .record-buttons .control-icon.disabled svg{
  cursor: default ;
}

.tracker .control-icon svg, .record-cuttons .control-icon svg{
  cursor: pointer;
}

.tracker .stop-icon-recording{
  padding-left: 20px;
}

.tracker .play-icon{
  width: 72px;
}

.tracker .pause-icon{
  width: 72px;
}


.record-buttons .record-icon{
  color:#7FFF00;
}

.record-buttons .record-icon-wait{
  color:#FFBE00
}


.record-buttons .stop-icon{

}

.stop-icon-recording{
  color: #F91C3D;
}


.tracker .tracker-controls .countdown-idle-timer{
  position: absolute;
  color: white;
  background: transparent;
  left: 25px;
  font-size: 27px;
  padding-top: 2px;
  padding-left: 1px;
}



.tracker .resume-icon{
  color:#7FFF00;
}


.indicators-container{
  flex: 0.8;
  display:flex;
  justify-content: space-around;
  padding-left: 115px;
  padding-right: 75px;
  color: #e55;
}

.tracker .heartrate{

}
.tracker .power{
}

.tracker .cadence{
}

.tracker .speed{
}

.ride-button {
  padding: 1rem;
}


.tracker-reset{
  position: absolute;
  left: 20px;
}

.tracker-red{
  color:#ff5;
}
.tracker-green{
  color:#f5f;
}
.tracker-white{
  color:#fff;
}



.video-wrapper{
  position: relative;
}

.video-overlay{
  width: auto;
  height: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  border-radius: 7.5px;
  color: white;
  z-index: 1000;

  /* Initially hidden */
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;

}

.overlay-visible{
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}


/* Leaderboard */


#leaderboard a{
  font-size: 26px;
  color: #fff;
}

#leaderboard-list {
  text-align: left;
  margin: 0;
  padding: 2px;
  font-size: 13px;
}

#leaderboard-list li {
  margin:2px;
  padding: 2px;
  background-color: #eee;
  color: black;
  display: block;
  text-decoration: none;
  opacity: 0.70;
}

#leaderboard-list li.racer-node.active {
  background-color: #4CAF50;
  color: white;
}

#leaderboard-list li.racer-node.current {
  background-color: #8F8F8F;
}



#leaderboard-list .titles-node{
   text-align:center;
   right: 54px;
   width: 300px;
   height: 24px;
   background-color: white;
   margin-top: 0px;
   margin-bottom: 0px;
   font-weight:bold;
}

.titles-node table{
  table-layout: fixed;
  height: 30px;
  width: 296px;
}

#leaderboard-list .racer-node{
  text-align: center;
  right: 54px;
  width: 300px;
  height: 30px;
  margin-top: 0px;
  margin-bottom: 2px;
}

.racer-node table{
  table-layout: fixed;
  height: 30px;
  width: 296px;
  position: relative;
  top: -1px;
}

.titles-node .position-col{
  font-weight: bold;
  width: 31px;
  padding-right: 2px;
}

.titles-node .power-col{
  width: 50px;
  text-align: center;
  padding-right: 7px;
}

.titles-node .name-col{
  width: 110px;
  text-align: left;
  padding-right: 7px;
}

.titles-node .cadence-col{
  width: 30px;
  text-align: left;
  padding-right: 7px;
}



.racer-node .position-col{
  font-weight: bold;
  width: 31px;
  padding-right: 2px;
}

.racer-node .power-col{
  width: 50px;
  text-align: center;
  padding-right: 7px;
}

.racer-node .name-col{
  width: 110px;
  text-align: left;
  padding-right: 7px;
}

.racer-node .cadence-col{
  width: 30px;
  text-align: left;
  padding-right: 7px;
}



/* Transparent Item */

.transparent-item{
  width: 300px;
  height: 15px;
  background-color: transparent!important;
  margin-top: 0px;
  margin-bottom: 3px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dot{
  height: 3px;
  width: 3px;
  background-color: white;
  border-radius: 50%;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.vertical-line {
  border-left: 1px solid black;
  margin-right:15px;
  padding-top: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}


.fullscreen-toggle{
  position: absolute;
  right: 18px;
  top: 1px;
}

video::-webkit-media-controls {
  display: none;
}


@media all and (max-width: 768px) {

  video{
    width: 100%;
  }

  .video-container{
    width: 100%;
    position: relative;
  }

  /* Responsive Gauges */

  .gauges{
    height: 40%;
    bottom: 50%;
    font-size: 20px;
    margin-left: 2%;
    width: 75px;
    min-width: 75px;
    font-family: Helvetica, Arial, sans-serif;
    text-shadow: black 1px 0px 0px;
    z-index: 999999;
    opacity: 1;
    color: white;
  }


  /* Responsive Control Icons */

  .control-icon:first-child{
    padding-left: 5px;
  }

  .tracker{
    height: 35px;
    bottom: 5%;
    font-size: 25px;
    margin-left: 2%;
    min-width: 20%;
    width: 96%;
  }

  .record-buttons{
    height: 35px;
    bottom: 50px;
    font-size: 25px;
    margin-left: 2%;
    min-width: 90px;
    width: 90px;
  }

  .control-icon{
    padding-right: 15px;
  }

  .tracker .play-icon{
    width: 42px;
  }

  .tracker .pause-icon{
    width: 42px;
  }

  /* Responsive Video Time */

  .video-time{
    font-size: 25px;
    padding-top: 5px;
  }

  /* Responsive Leaderboard */

  .leaderboard{
    border-radius: 2px;
    margin:0px;
    top: 2%;
    right: 1%;
    font-size: 20px;
    width: 200px;
  }

  #leaderboard a{
    font-size: 17px;
    color: #fff;
  }

  #leaderboard-list {
    text-align: left;
    margin: 0;
    padding: 2px;
    font-size: 13px;
  }

  #leaderboard-list li {
    margin:2px;
    padding: 2px;
    background-color: #eee;
    color: black;
    display: block;
    text-decoration: none;
    opacity: 0.70;
  }

  #leaderboard-list li.racer-node.active {
    background-color: #4CAF50;
    color: white;
  }

  #leaderboard-list li.racer-node.current {
    background-color: #8F8F8F;
  }

  #leaderboard-list .titles-node{
     text-align:center;
     right: 54px;
     width: 300px;
     height: 24px;
     background-color: white;
     margin-top: 0px;
     margin-bottom: 0px;
     font-weight:bold;
     opacity:0.7;
  }

  .titles-node table{
    table-layout: fixed;
    height: 30px;
    width: 296px;
  }

  #leaderboard-list .racer-node{
    text-align: center;
    right: 54px;
    width: 300px;
    height: 30px;
    margin-top: 0px;
    margin-bottom: 2px;
    opacity:0.7;
  }

  .racer-node table{
    table-layout: fixed;
    height: 30px;
    width: 296px;
    position: relative;
    top: -1px;
  }

  .titles-node .position-col{
    font-weight: bold;
    width: 31px;
    padding-right: 2px;
  }

  .titles-node .power-col{
    width: 50px;
    text-align: center;
    padding-right: 7px;
  }

  .titles-node .name-col{
    width: 110px;
    text-align: left;
    padding-right: 7px;
  }

  .titles-node .cadence-col{
    width: 30px;
    text-align: left;
    padding-right: 7px;
  }



  .racer-node .position-col{
    font-weight: bold;
    width: 31px;
    padding-right: 2px;
  }

  .racer-node .power-col{
    width: 50px;
    text-align: center;
    padding-right: 7px;
  }

  .racer-node .name-col{
    width: 110px;
    text-align: left;
    padding-right: 7px;
  }

  .racer-node .cadence-col{
    width: 30px;
    text-align: left;
    padding-right: 7px;
  }



  /* Transparent Item */

  .transparent-item{
    width: 300px;
    height: 24px;
    background-color: transparent!important;
    margin-top: 0px;
    margin-bottom: 3px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .dot{
    height: 3px;
    width: 3px;
    background-color: white;
    border-radius: 50%;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }



}

/*.edit-box-holder{ 
}

.edit-box {
  float:right;
  margin-top:10px;
}
*/
/*.video-description{
  padding-top: 30px;
  text-align: center;
  border-color: #ddd;
}*/

/*.title-input{
  width:100%;
  height: 50px;
  margin-bottom: 20px;
  font-size: 25px;
  border-radius: 10px;
  padding: 10px;
}
*/
/*.video-title-edit-header {  
  color: black;
  padding-bottom: 5px;
}*/

/*.checkbox-row {
  width:980px;
  height: 50px;
  margin-top:0px;
}*/

/*.video-description-edit-header {
  color: black;
  padding-bottom: 5px;
}*/

/*.description-input{
  width:100%;
  height: 100px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}*/

.video-bottom{
  /*width: 960px;*/
  margin: 30px auto;
}

/*.btn.btn-primary.edit-save{
  background-color: #28a745;
  border:none;
}

.submit-button-edit {
  float:right;
}

.submit-column {
  position:relative;
}
*/
.image, .wrap {
    height: 100%;
    padding: 0;
    margin: 0;
}

.wrap{
  position: relative;
  background-color: #000;
}

.btn.race-button{
  margin-left: 25px;
  margin-bottom: 7px;
  background-color: #F91C3D;
  color: white;
}

.btn.race-button:hover{
  color: white;
}

.countdown-timer{
  font-size: 50px;
  color: #F91C3D;
  top: 30%;
  font-size: 150px;
  color: #F91C3D;
  position: absolute;
  width: 100%;
  text-align: center;
}

.img-mobile-thumb{
  width:100%;
}

.demo-sign{
  font-size: 30px;
  color: #F91C3D;
  position: absolute;
  top: 200px;
  left: 21px;
  z-index: 1;
}

.vjs-loading-spinner .vjs-control-text{
  display: none;
}


.full-height{
  height: 100vh;
}

.theater-mode{
  position: absolute;
  top:0;
}

.video-js.vjs-16-9 {
  padding-top: 0 !important;
}

.vjs-tech {
  object-fit: cover !important;
}

.vjs-poster{
  object-fit: cover !important;
}

.theater-mode .race-video-dimensions{
  width: 100vw !important;
  height: 100vh !important;
}

.overlay-title{
    text-align: center;
    padding: 0.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
    z-index: 999999;
    padding-bottom: 25px;
}

.button-container {

}

.mode-indicator {
  position: absolute;
  top: 12%;
  right: 1%;
  z-index: 2;
  font-size: 1.5vw;
}

.mode-indicator-electron {
  position: absolute;
  top: 70px; /* Vee: changed from 5% */
  right: 15px; /* Vee: changed from 2% */
  z-index: 2;
  font-size: 1.5vw;
}


.indicator-container-box {
  background: white;
  border-radius: 0.5rem;
  opacity: 0.85;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.indicator-container-box-electron {

}

.bottom-container {
  z-index: 999999!important;

}

.race-video-dimensions {
  height: auto!important;
}


.view-channel {
  position: absolute;
  right: 0;
  top: 25%;
}

.WR-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.WR-row {
  height: 30%;
  background-color: black;
}

.WR-col {

}

.WR-button-container {
  text-align: center;
  height: 15%;
}

.WR-bg {
  background: rgba(220, 220, 221, 0.8);
}

.WR-userlist {
  height: 70%;
  margin-top: 5%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1rem;
  position: relative;
}

.WR-userlist-title {
  height: 15%;
}

.WR-userlist-wrapper-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70%
}

.WR-userlist-wrapper {
  text-align: center;
  height: 70%
}

.WR-waiting-button-container {
  text-align: center;
}

.WR-spinnerbox {

}

.parent-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

