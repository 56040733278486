.button-holder-submit {
	padding-left: 0!important;
	padding-top: 20px;
}

.header-button {
	display: block;
	margin-right: 10px;
	display: block;
	margin-top: 10px;
	padding: 5px;
}

.invert{
   -webkit-filter: invert(100%);
}


.modal{ display:block; }