.overlay-buttons{
  min-width: 10rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  z-index: 9999;
}

.overlay-button-container {
  margin: 0.25rem;
}

.overlay-clickable {
  min-width: 6.5rem;
}


/*.overlay-buttons-toptext {
  padding-top: 1vw;
  padding-left: 1.75vh;
  padding-right: 1.75vh;
  padding-bottom: 0.5vw;
  font-size: 3vw;
  line-height: 90%;
}

.overlay-buttons-bottomtext {
  padding-left: 0.25vh;
  padding-right: 0.25vh;
  padding-bottom: 1vw;
}*/

.overlay-text {
  color: black!important;
}

#main-overlay{
  position:absolute;
  top:50px;
  background-color:rgba(0, 0, 0, 0.85);
  background: url(data:;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABl0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuNUmK/OAAAAATSURBVBhXY2RgYNgHxGAAYuwDAA78AjwwRoQYAAAAAElFTkSuQmCC) repeat scroll transparent\9; /* ie fallback png background image */
  z-index:999;
  color:white;
  overflow-y: visible;
}

@media only screen and (max-width: 768px) {
  #main-overlay{
    top: 15%;
    left:15px;
    right:15px;
    height: 300px;
  }
}

@media only screen and (min-width: 768px) {
  #main-overlay{
    left:175px;
    right:175px;
    height: 600px;
    top: 50px;
  }

  body .big-x{
    right: 100px;
  }

}

#main-overlay-video{
  width: 100%;
}

#whole-screen{
  position   : absolute;
  top        : 0;
  left       : 0;
  width      : 100%;
  height     : 100%;
  background : #000;
  opacity    : 0.6;
  filter     : alpha(opacity=60);
  z-index    : 5;
}

.big-x{
  position: absolute;
  right: 30px;
  top: 30px;
  color: white;
  opacity: 1;
  z-index: 999;
  font-size: 36px;
  cursor: pointer;
}

.video-being-processed-msg{
  margin-top: 54px;
}

/* 
Control Panel 
*/


.record-column {
  text-align:center;
}

.save-column {

}

.form-control {
  height:50!important;
}

.time-entry-column {
  width: 100%;
  height: 50;
}

.time-entry-input {
  height: 48;
  width: 100%;
}

.time-entry-button {
  width:100%;

}

.actual-button-entry {
  width: 100%;
  /* padding-right: 3rem; */
  margin-right: 5px;
}

.section-title {
  text-align: left;
}

.delete-overlay-button {
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
}

.react-waves {
  width:100;
  padding:0;
  display: block;
}

.play-pause-button {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.time-container {
  text-align: center;
}

.tabber{
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
    z-index: 999999;
}




