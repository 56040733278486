body .search-input {
  margin-left: 20px;
  padding-left: 20px;
  /*display: inline-block;*/
}

.dropdown {
  display: inline-block;
  margin-left: 15px;
}

.gallery-ride {
  margin: 20px 0px;
}

.gallery-ride-channels {
  margin: 0px 0px;
}
.gallery-video-title {
 font-weight: normal;
/* font-size: calc(.25rem + 1vw); */
 font-size: min(max(1rem, 2vw), 16px);
}

.gallery-video-title-small {
 font-weight: normal;
/* font-size: calc(.25rem + 1vw); */
 font-size: min(max(1rem, 2vw), 13px);
}

.img-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  overflow: hidden;
}


.img-container VFImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-image{
  position: absolute !important;
  bottom: 10px !important;
  right: 10px !important;
  width: 30px !important;
  height: 30px !important;

}

.overlay-image:hover > videobox:hover {
  opacity: 1.0;
}

/* REMOVE ALL THE BELOW AS THEY APPEAR TO BE UNUSED: */

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  background: #000000;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
}

.hovereffect img {
  display: block;
  position: relative;
  /* max-width: none; */
  /* width: calc(100% + 20px); */
  width: 100%;
  min-height: 200px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(0px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hovereffect:hover img {
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect div.text-overlay, .hovereffect h4 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  overflow: hidden;
  padding: 0.5em 0;
  background: rgba(0, 0, 0, 0.6);
}

.hovereffect div.text-overlay{
  font-size:0.7em;
  background: rgba(0, 0, 0, 0.6);
  height:60px;
  border-radius: 4px;
  padding:4px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625;
}

.hovereffect h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}

.hovereffect:hover h2:after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect a, .hovereffect p {
  color: #FFF;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

.hovereffect:hover a, .hovereffect:hover p {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* change name of time-indicator class below to distinguish it from one in app css */

.time-indicator {
  position: absolute;
  bottom: 22%;
  left: 12%;
  color: white;
  font-weight: bold;
}

.gradient-image {
  width: 100%;
  height: 100%;
}
