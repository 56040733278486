.hero-video-container {
  position: relative;
}

.hero-video-container video {
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.hero-video-overlay {
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 8vh;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-gradient, .btn-gradient:hover, .btn-gradient:focus {
  /* background: linear-gradient(90deg, rgba(10,29,63,1) 0%, rgba(196,44,55,1) 100%); */
  /* background: rgba(10,29,63,1)!important; 
  border-color: white!important; */
}

/* 
@include media-breakpoint-up(sm) {
  .text-responsive {
    font-size: calc(150% + 1.2vw + 1.25vh);
  }
}

@include media-breakpoint-up(md) {
  .text-responsive {
    font-size: calc(200% + 1.2vw + 1.25vh);
  }
}

@include media-breakpoint-up(lg) {
  .text-responsive {
    font-size: calc(250% + 1.2vw + 1.25vh);
  }
} */

/* .xtext-responsive {
  font-size: calc(150% + 1.2vw + 1.25vh);
}

.xtext-responsive-2 {
  font-size: calc(90% + .9vw + .9vh);
}

/* .text-responsive {
  font-size: 36px;
}
.text-responsive-subhead {
  font-size: 18px;
}
@media screen and (min-width: 320px) {
  .text-responsive {
    font-size: calc(36px + 6 * ((.50vw - 320px) / 680));
  }
  .text-responsive-subhead {
    font-size: calc(18px + 6 * ((.50vw - 320px) / 680));
  }
}
 @media screen and (min-width: 1000px) {
  .text-responsive {
      font-size: 108px;
    }
  .text-responsive-subhead {
    font-size: 48px;
  }
}  */

.portraitview {
    margin-top: -130px;
    display: inline!important;
    }

.hero-home-textbox h1 {
    font-size: 175%;
    font-weight: bold;
    }
.hero-home-textbox h2 {
    font-size: 120%;
    }
.hero-home-textbox h1 {
    padding-top: 100%;
    }

@media (min-width: 300px) {
      .portraitview {
        margin-top: -120px;
        } 
      .hero-home-textbox h1 {
          font-size: 180%;
          font-weight: bold;
          }
      .hero-home-textbox h2 {
          font-size: 115%;
          }
      .hero-home-textbox h1 {
          padding-top: 80%;
          }
      }

@media (min-width: 450px) {
        .portraitview {
          margin-top: -105px;
          } 
        .hero-home-textbox h1 {
          font-size: 250%;
          font-weight: bold;
          }
        .hero-home-textbox h2 {
          font-size: 130%;
          }
        .hero-home-textbox h1 {
          padding-top: 70%;
          }  
        }
  
@media (min-width: 660px) {
  .portraitview {
    margin-top: -65px;
    } 
  html {
    font-size: 110%;
    }
  .hero-home-textbox h1 {
    font-size: 350%;
    }
  .hero-home-textbox h2 {
    font-size: 142%;
    }
  .hero-home-textbox h1 {
    padding-top: 50%;
    }
  }

@media (min-width: 800px) {
  .portraitview {
    margin-top: -45px;
    } 
  html {
    font-size: 120%;
    }
  .hero-home-textbox h1 {
    font-size: 425%;
    }
  .hero-home-textbox h2 {
    font-size: 190%;
    }
  .hero-home-textbox h1 {
    padding-top: 75%;
    }
  }

@media (min-width: 950px) {
    .portraitview {
      margin-top: 15px;
      } 
    }

@media (min-width: 1200px) {
  .portraitview {
    margin-top: 10px;
    } 
  html {
    font-size: 140%;
    }
  .hero-home-textbox h1 {
    font-size: 525%;
    }
  .hero-home-textbox h2 {
    font-size: 225%;
    }
  .hero-home-textbox h1 {
    padding-top: 75%;
    }
  }

@media (min-width: 1300px) {
    .portraitview {
      margin-top: 20px;
      } 
  }

.hero-home-textbox h1 {
  white-space: nowrap;
}
.hero-home-textbox h2 {
  line-height: 50%;
  white-space: nowrap;
}

.hero-home-textbox {
  position: relative;
  top: 35%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
}

.hero-container {
  position: relative;
  background-color: white; /* #031E41*/
  max-height: 65vh;
  /* min-height: 25rem; */
  width: 100%;
  overflow: hidden;
}

.hero-container .container {
  position: relative;
  z-index: 2;
}

.hero-container video {
  position: absolute;
  top: 39%;
  left: 50%;
  width: 100%; /*added*/
  /* min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto; */
  background: #031E41; /*added*/
  opacity: 0.9;
  z-index: 0;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.hero-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  /* background: linear-gradient(270deg, rgba(10,29,63,0.7763480392156863) 30%, rgba(196,44,55,1) 100%); */
  /*background: linear-gradient(0deg,hsla(0,0%,96.9%,.6334908963585435) 14%,#5b3183 89%);
  background: linear-gradient(0deg, rgba(247,247,247,0.6334908963585435) 14%, rgba(91,49,131,1) 89%); */
  /* background: #031E41; */
  /* opacity: 0.1; */
  z-index: 3; /* was 1 */
}

.usac-promo
 {
  background-color: #031E41!important;
  color: #ffffff;
  }

.usac-promo h5 {
 font-size: 4.5vw;
}

/* where are these used? */

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

.red .active a,
.red .active a:hover {
    background-color: red !important;
}

.nav > .active > a, .nav-tabs > .active > a:hover {
    background-color: red !important;
}

a.nav-link, a.nav-link:visited, a.nav-link:focus, a.nav-link:hover, a.nav-link:active, 
.panel-text a, .panel-text a:visited, .panel-text a:focus, .panel-text a:hover, .panel-text a:active,
.panel-title a, .panel-title a:visited, .panel-title a:focus, .panel-title a:hover, .panel-title a:active,
a.panel-title /* << keep this last one too! */
{
  color: #000000; font-weight: bold; text-decoration: none!important;
}

.badge {
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  padding: 3px 6px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 99999px
}

.badge.badge-success {
  background-color: #28a745;
}

.badge.badge-outlined {
  background-color: transparent
}

.badge.badge-outlined.badge-success {
  border-color: #28a745;
  color: #28a745;
}

.overlay-buttons{
  min-width: 10rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  z-index: 9999;
}

.overlay-button-container {
  margin: 0.25rem;
}

.overlay-clickable {
  min-width: 6.5rem;
}


/*.overlay-buttons-toptext {
  padding-top: 1vw;
  padding-left: 1.75vh;
  padding-right: 1.75vh;
  padding-bottom: 0.5vw;
  font-size: 3vw;
  line-height: 90%;
}

.overlay-buttons-bottomtext {
  padding-left: 0.25vh;
  padding-right: 0.25vh;
  padding-bottom: 1vw;
}*/

.overlay-text {
  color: black!important;
}

#main-overlay{
  position:absolute;
  top:50px;
  background-color:rgba(0, 0, 0, 0.85);
  background: url(data:;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAABl0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuNUmK/OAAAAATSURBVBhXY2RgYNgHxGAAYuwDAA78AjwwRoQYAAAAAElFTkSuQmCC) repeat scroll transparent\9; /* ie fallback png background image */
  z-index:999;
  color:white;
  overflow-y: visible;
}

@media only screen and (max-width: 768px) {
  #main-overlay{
    top: 15%;
    left:15px;
    right:15px;
    height: 300px;
  }
}

@media only screen and (min-width: 768px) {
  #main-overlay{
    left:175px;
    right:175px;
    height: 600px;
    top: 50px;
  }

  body .big-x{
    right: 100px;
  }

}

#main-overlay-video{
  width: 100%;
}

#whole-screen{
  position   : absolute;
  top        : 0;
  left       : 0;
  width      : 100%;
  height     : 100%;
  background : #000;
  opacity    : 0.6;
  filter     : alpha(opacity=60);
  z-index    : 5;
}

.big-x{
  position: absolute;
  right: 30px;
  top: 30px;
  color: white;
  opacity: 1;
  z-index: 999;
  font-size: 36px;
  cursor: pointer;
}

.video-being-processed-msg{
  margin-top: 54px;
}

/* 
Control Panel 
*/


.record-column {
  text-align:center;
}

.save-column {

}

.form-control {
  height:50!important;
}

.time-entry-column {
  width: 100%;
  height: 50;
}

.time-entry-input {
  height: 48;
  width: 100%;
}

.time-entry-button {
  width:100%;

}

.actual-button-entry {
  width: 100%;
  /* padding-right: 3rem; */
  margin-right: 5px;
}

.section-title {
  text-align: left;
}

.delete-overlay-button {
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  display: inline-block;
  padding-right: 10px;
}

.react-waves {
  width:100;
  padding:0;
  display: block;
}

.play-pause-button {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.time-container {
  text-align: center;
}

.tabber{
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
    z-index: 999999;
}





.button-holder-submit {
	padding-left: 0!important;
	padding-top: 20px;
}

.header-button {
	display: block;
	margin-right: 10px;
	display: block;
	margin-top: 10px;
	padding: 5px;
}

.invert{
   -webkit-filter: invert(100%);
}


.modal{ display:block; }
._react_fileupload_form_content [type="submit"]{
	margin-top: 30px;
}

.row .form-subtitle{
  font-size: 23px;
  font-weight: normal;
  margin-bottom: 0px;
}
/* RESPONSIVE TYPOGRAPHY (combine/reconcile these) */

/* start 1 */

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 100%;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.2rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1.6rem;
  }
}

/* end 1 */

/* start 2 */

.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head { font-size:8.75vw !important }
.section-subhead { font-size:.75rem }
.showcase-row { padding: 1.5rem 2rem 3rem !important; }
.pricing-card { width: 290px }
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 10vw; line-height: 10vw; font-weight: 600; }
/* .page-subheadingX { font-size: 5vw; } */

@media (min-width:375px){
  .pricing-card {width: 345px }
}

@media (min-width:485px){
.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head {font-size:9vw !important}
.section-subhead {font-size:.75rem}
.showcase-row {padding: 1.5rem 3rem 3rem !important;}
.pricing-card {width: 340px }
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 10vw; line-height: 10vw }
}
@media (min-width:576px){
.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head {font-size:7vw !important}
.section-subhead {font-size:.75rem}
.showcase-row {padding: 1.5rem 3rem 3rem !important;}
.pricing-card {width: 340px }
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 9vw; line-height: 9vw }
}
@media (min-width:768px){
.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head {font-size:4.1vw !important}
.section-subhead {font-size:1rem}
.showcase-row {padding: 1rem 3rem 2rem !important;}
.pricing-card {width: 350px }
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 8vw; line-height: 8vw }
}
@media (min-width:992px){
.hero-heading { font-size: 8vw; }
.hero-subheading { font-size: 5vw; }
.down {display: none;}
.section-head {font-size:4.5vw !important}
.section-subhead {font-size:1rem}
.showcase-row {padding: 1rem 3rem 1rem !important;}
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 6.5vw; line-height: 6.5vw }
}
@media (min-width:1280px){
.hero-heading { font-size: 6vw; }
.hero-subheading { font-size: 3vw; }
.down {display: none; }
.section-head {font-size:4.5vw !important}
.section-subhead {font-size:1.5rem}
.showcase-row {padding: 1rem 3rem 1rem !important;}
.home-featuredphrase, .activities-first-time-heading, .events-first-time-heading, .pricing-first-time-heading { font-size: 5vw; line-height: 5vw }
}

.badge-new {
font-size: 1.5vh!important;
vertical-align: top;
position: relative;
border-radius: .25rem;
}

/* .more {
  position: absolute;
  z-index: 100000;
}

.content {
  position: relative;
} */


/* end 2 */


/* start 3 */

.logo-main{
  width:250px;
  cursor: pointer;
}

@media only screen and (max-width: 320px) {
  .logo-main{
    width:260px;
  }
  .main-title{
    font-size:1.3em;
  }
  .main-image{
    width:280px;
    display: inline-block;
  }
   .main-image-lg{
     display: none;
   }
  .main-text{
    width:280px;
  }
}

@media only screen and (min-width: 550px) {

  .logo-main{
    width:380px;
  }
  .main-title{
    font-size:2.0em;
  }
  .main-image-lg{
    width:800px;
    display: inline-block;
  }
  .main-text{
    width:500px;
  }
 .main-image{
   display: none;
 }
}

@media only screen and (min-width: 550px) {
  .main-image-lg{
    width:500px;
    display: inline-block;
  }
}

@media only screen and (min-width: 990px) {
  .logo-main{
    width:380px;
  }
  .main-title{
    font-size:2.0em;
  }
  .main-image-lg{
    width:800px;
    display: inline-block;
  }
  .main-text{
    width:780px;
    font-size:1.4em;
  }
 .main-image{
   display: none;
 }
}

/* end 3 */



/* start 4 */

.font-size-mlg{
  font-weight:600;
  font-size:2.5em;
}

.font-size-xlg{
  font-size:1.5em;
  font-weight: bold;
  width: 100%;
}

/* end 4 */

/* END responsive type section */

.add-bottom-margin{
  margin-bottom: 20px;
}

.activity-panel{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: normal;
  background-color: #f6f6f6;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  width:100%;
  margin-bottom: 10px
}

.activity-title{
  font-size:1.1em;
  font-weight:700;
}

.activity-date{
  font-size:0.8em;
  font-weight:400;
}

.activity-link{
  font-size:1.2em;
  font-weight:700;
}

.activity-header{
  font-size:0.7em;
}

.activity-value{
  font-size:1em;
  font-weight:400;
  padding-left: 15px!important;
  padding-right: 10px!important;
  word-wrap: normal;
}

.dropdown-menu-center {
    left: -50px !important;
}

.learn-more{
    margin-bottom: 15px;
  }

.list-inline-item.blank-li, .list-inline-item.try-our-software{
    display: none;
  }

/* END */

body {
  margin: 0;
  padding: 0;
  padding-top: 55px;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 1.5;
}



#repeat-x { background-repeat: repeat-x; }

/* body {
  background: url(/bg-nav.png);
} */

a.nav-link.nav-vf {
  display: ;
  padding: 0rem;
}

.nav-color, .footer-color {
  background: rgb(10,29,63);
  background: -webkit-gradient(linear, right top, left top, color-stop(30%, rgba(10,29,63,0.7763480392156863)), to(rgba(196,44,55,1)));
  background: linear-gradient(270deg, rgba(10,29,63,0.7763480392156863) 30%, rgba(196,44,55,1) 100%);
  /* background: linear-gradient(90deg, rgba(10,29,63,1) 0%, rgba(196,44,55,1) 100%); */
}

.navbar-bottom {
background: rgb(10,29,63);
background: -webkit-gradient(linear, left top, right top, from(rgba(10,29,63,1)), to(rgba(196,44,55,1)));
background: linear-gradient(90deg, rgba(10,29,63,1) 0%, rgba(196,44,55,1) 100%);
}

.navbar {
  padding: 0rem!important;
  padding-left: 3rem!important;
}

.dropup .dropdown-menu {
  top: auto;
  margin-bottom: .125rem;
}

.navbar-bottom {
    height: 55px;
    padding: 0rem!important;
    padding-left: 0rem!important;
}

.navbar-trans {
  background: transparent; }


.navbar-toggler {
  padding: .25rem 0!important;
}

.navbar-toggle.collapsed {
  background-color: #000000!important;
}

#navbarDropdownMenuLink, 
  #vidracing-navbar-mobile > ul > li.nav-item.dropdown.pt-2.show > div > a, 
  #vidracing-navbar-mobile > ul > li > a, 
  #vidracing-navbar-mobile > ul > li.nav-item.dropdown.pt-2.pr-2.pr-md-5.show > div > div > a,
  #root > div > div > nav.navbar.navbar-expand.navbar-default.navbar-dark.fixed-bottom.d-flex.justify-content-center.nav-color.navbar-bottom > div > ul > li > a
  {
  font-size: .95rem;
  }

#vidracing-navbar-mobile > ul > li > a, 
  #navbarDropdownMenuLink, 
  #root > div > div > nav.navbar.navbar-expand.navbar-default.navbar-dark.fixed-bottom.d-flex.justify-content-center.navbar-bottom > div > ul > li > a,
  #vidracing-navbar-mobile > ul > div > li > a
 {
  color: #ffffff;
}

.dropdown {
  margin-left: 0px!important;
}

a.dropdown-toggle.download-nav::after {
  display: none;
}

dropdown-toggle .dropdown-profile::after {
    content: none!important; 
    display:none!important; 
    border-top: 0rem!important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
background-color: #ffffff!important;
border-color: #cccccc;
border-style: solid;
border-width: 1px;
}

.navbar-main a.nav-link {
  font-weight: normal!important;
}

.video-page-tabs .nav-link, .video-page-tabs .nav-link:hover, .video-page-tabs .nav-link.active {
  font-weight: normal!important;
}
 
.page-item.active .page-link {
  background-color: #333333!important;
  border-color: #333333!important;
}

.page-link:hover {
  /*color: #cccccc!important;
  background-color: #cccccc!important;*/
}

.challenges-detail-link:hover {
  text-decoration: underline!important;
}

a.help-link:hover {
  text-decoration: underline!important;
}

/* used in activities placeholder only (Bootstrap class instead?) */
.img-cover-fit {
  object-fit: cover;
}
/* end */

h3.page-title, h3.sidebar-title, h3.sidebar-title-profilename, h3.section-subhead {
  font-weight: 600;
  font-size: 175%;
}

.event-list {
    padding: 0;
    list-style-type: none;
}

/* start radio toolbar - unused */

.radio-toolbar {
  margin: 10px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
    display: inline-block;
    background-color: transparent;
    color: #000000;
    padding: 10px 20px;
    border: 1px solid #0A1D3F;
    border-radius: 4px;
}

.radio-toolbar label:hover {
  background-color: #cccccc;
  border: 1px solid #0A1D3F;
}

.radio-toolbar input[type="radio"]:focus + label {
    border: 1px solid #0A1D3F;
}

.radio-toolbar input[type="radio"]:checked + label {
    background-color: #0A1D3F;
    color: #ffffff;
    border: 1px solid #0A1D3F;
}

/* end */

.footer {
  color: #000000; /*text color*/
  background: #ffffff;
}

a.footer-link, a:link.footer-link, a:visited.footer-link {
  color: #000000;
}

a:hover.footer-link, a:active.footer-link {
  color: #000000;
}

.card-footer, .card-footer a:link, .card-footer a:visited {
  color: #000000!important;
  text-decoration: none!important;
  }

.card-footer {
  font-size: 95%;
  line-height: 1.1em;
  border-bottom: none;
  }

.card-footer a:hover {
  color: #000000!important;
  text-decoration: none!important;
  }

.card-footer-subtext {
  font-size: 80%;
  }

.card-footer-subtext, .card-footer-subtext a:link, .card-footer-subtext a:visited, .card-footer-subtext a:active {
  color: #696969;
  }

.feature-see-all, .feature-see-all a:link, .feature-see-all a:visited, .feature-see-all a:hover, .feature-see-all a:active
  {
  color: #000000!important;
/*  color: #28a745!important; */
  font-size: 1rem;
  font-weight: normal;
  display: inline-block;
  }

  .feature-see-all a:hover {
    text-decoration: underline; 
  }

.feature-row-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  }

.feature-row-title {
  font-size: 175%;
  font-weight: 600;
  display: flex;
  align-items: center;
  }

.img-zoom {
  -webkit-transition: -webkit-transform .15s;
  transition: -webkit-transform .15s;
  transition: transform .15s;
  transition: transform .15s, -webkit-transform .15s;
  }

.img-zoom:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05); /* (150% zoom)*/
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  }

.gallery-card {
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;
  }

.gallery-image {
  top: 0px;
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;
  }

.gallery-card-hero {
  width: 100%;
  top: 0px;
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;
  }

.gallery-image-hero {
  width: 100%;
  top: 0px;
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;
  }

.time-indicator {
  color: #ffffff;
  background: transparent;
  font-weight: bold;
  position: absolute;
  top: 1.5rem;
  }

/* combine these two in pages later. */
.gradient-icon, .gradient-indicator {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  }

/* .fa-angle-left { font-size: 3rem; position: absolute; left:5rem;
  }

.fa-angle-right { font-size: 3rem; position: absolute; right:5rem;
  }
 */
 
.showcase {
  align-items: center
}

/* used in audio overlay and pricing */

.custom-switch.custom-switch-sm .custom-control-label {
    padding-left: 1rem;
    padding-bottom: 1rem;
    color: #28a745;
}

.custom-switch.custom-switch-sm .custom-control-label::before {
    height: 1rem;
    width: calc(1rem + 0.75rem);
    border-radius: 2rem;
    color: #28a745;
}

.custom-switch.custom-switch-sm .custom-control-label::after {
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    border-radius: calc(1rem - (1rem / 2));
    color: #28a745;
}

.custom-switch.custom-switch-sm .custom-control-input:checked ~ .custom-control-label::after {
    -webkit-transform: translateX(calc(1rem - 0.25rem));
            transform: translateX(calc(1rem - 0.25rem));
    color: #28a745;
}

/* end */

.event-status {
  z-index: 99!important;
  position: absolute;
  top: 9%!important;
  right: 6%!important;
  text-align:center;
}

.event-status-real {
  z-index: 99!important;
  position: absolute;
  top: 9%!important;
  left: 6%!important;
  text-align:center;
}

/* ASPECT RATIO FOR VARIOUS GALLERY VIEWS */
  /* With no other attributes set on this class, the "relative" value is not really changing the position of the div that has this class, but it allows its child elements to be positioned absolutely relative to their parent. */

.aspect16x9 {
  position: relative;
  }

/* Create a pseudo element that uses padding-bottom to take up space to create a 16:9 aspect ratio box. */

.aspect16x9::after {
  display: block;
  content: '';
  /* 16:9 aspect ratio */
  padding-bottom: 56.25%;
  }

/* Image is positioned absolutely relative to the parent element. */
.aspect16x9 img {
  /* Image should match parent box size. */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* Rounded (move this to separate class, as already used above): */
  border-top-left-radius: 0.75rem; border-top-right-radius: 0.75rem; border-bottom-right-radius: 0.75rem; border-bottom-left-radius: 0.75rem;

  }

/* BELOW IS USED FOR OVERLAY BUT DETERMINE HOW IT INTERACTS WITH SIBLING CLASS ASPECTRATIO16X9 THEN SIMPLY IF NEEDED. */

/* Parent Container */
/*.videobox-UNUSED {
 position: relative;
 width: 98%;
 height: 98%;
 float: left;
 margin-right: 10px;
}*/

/* Child Text Container */
.videobox div{
 position: absolute;
 top: 0;
 left: 0;
 background: white;
 color: black;
 margin-bottom: 5px;
 font-family: sans-serif;
 opacity: 0;
 visibility: hidden;
 -webkit-transition: visibility 0s, opacity 0.5s linear;
 transition: visibility 0s, opacity 0.5s linear;
}

/* Hover on Parent Container */
.videobox:hover{
 cursor: pointer;
}

.videobox:hover div{
 width: 100%;
 height: 100%;
 visibility: hidden;
 opacity: 0.7;
}

/* END */


/* ====================================================

    Review classes below to see:
      1) which are no longer being used, and 
      2) which can be accomplished by standard bootstrap classes (or customized standard classes)
*/

button:focus {
  outline: 0;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.add-video-icon{
  margin-left: 20px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.login-panel-container{
  height: 100vh; 
}

.main-image{
  width:300px;
}

.mail-title{
  /*font-size:1.0em; */
}

.main-text{
  width:300px;
  font-size:1.1em;
}

.main-image-lg{
  width:500px;
  display: none;
}

.screen-shot{
  width:100%;
}

.setup-bullet{
  width:60px;
}

.nav-red{
  background-color: #F91C3D;
  font-size:1.2em;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.terms-and-services{
  width:90%;
}

.container-centered {
    height: 100%;
    /* display: flex;
    justify-content: center; */
    align-items: center;
    margin: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.login-title {
  font-size:32px;
  font-weight: 500;
  margin-bottom: 30px;
}

.login-panel {
  height:600px;
}

.terms-and-services-panel{

}

.terms-and-services-panel-2{
  font-size: 14px;
  position: relative;
  /* left: 27px; */
  top: 10px;
}

/* various links */ 

.terms-and-services-panel a, .terms-and-services-panel a:link, .terms-and-services-panel a:visited, .terms-and-services-panel a:hover, .terms-and-services-panel a:active,
.terms-and-services-panel-2 a, .terms-and-services-panel-2 a:link, .terms-and-services-panel-2 a:visited, .terms-and-services-panel-2 a:hover, .terms-and-services-panel-2 a:active,
.forgot-password a, .forgot-password a:link, .forgot-password a:visited, .forgot-password a:hover, .forgot-password a:active {
  color: black;
}

.terms-and-services-panel a:hover, .terms-and-services-panel2 a:hover, .forgot-password a:hover {
  text-decoration: underline;
}

.feature-row-title a:hover { text-decoration: none; }

.font-size-lg{
  font-size:1.4em;
}

.m-r-1 {
  margin-right: 0.5em;
}

.m-t-1 {
  margin-top: 0.5em;
}

.m-t-2 {
  margin-top: 1.0em;
}

.m-t-3 {
  margin-top: 1.5em;
}

.m-b-1 {
  margin-bottom: 0.5em;
}

.m-b-2 {
  margin-bottom: 1.0em;
}

/* .m-b-3 {
  margin-bottom: 1.5em;
} */

.facebook_button {
  position: relative;
  padding: 10px 47px;
  text-align: center;
  width: 94px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  height: 44px;
  margin-bottom:15px;
}

.facebook-icon{
  position: absolute;
  left: 24px;
  font-size: 34px;
  bottom: 8px;
}

.sign-in-button .envelope-icon{
  color:black;
  width: 31px;
  height: 80px;
  position: absolute;
  top: -15px;
  left: 16px;
}

.trash-icon{
  color: #C0C0C0;
  font-size: 25px;
  vertical-align: middle;
}

.google_logo{
  position: absolute;
  left: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 35px;
}

.sign-in-button {
  /* margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 318px; */
  display: block;
  font-weight: bold;
  height: 52px;
  background-size: 24px;
  background-position: 16px center;
  background-repeat: no-repeat;
  font-size: 16px;
  position: relative;
  text-align: center;
  border: 1px solid #ccccd1;
  margin-top: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 52px;
  padding-right: 8px;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 1.42857143;
  cursor:pointer;
} 

.facebook-sign-in-button{
  color: #fff !important;
  background-color: #3b5998;
  border-color: #3b5998;
}

/* .google-sign-in-button{
  left:20px;
  color: #2d2d32;
  background-color: #fff;
  border: 1px solid #ccccd1;
} */

.in-touch{
  text-align: center;
  font-size: 30px
}

.not-authorized-jumbotron{
  margin-top: 150px;
  text-align: center;
}

.email-verified-jumbotron{
  margin-top: 25px;
  text-align: center;
}

.wrench-icon{
  font-size: 100px;
  color: #FF5722;
}

.vidRacer-hr{
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid black;
  margin: 1em 0; padding: 0;
}

.vidRacer-hr-gray{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid gray;
  margin: 0.5em 0 1em 0; padding: 0;
}

/* Profile Page */

#profile-form .profile-label{
  padding-right: 1px;
  text-align: right;
  font-weight:600;
}

#profile-form .form-group.row:first-of-type{
  border-top: 1px solid #e6e6eb;
}

#profile-form .form-control.narrow, #profile-form .form-control-plaintext.narrow{
  width: 70%;
  display: inline;
  padding-right: 0px;
}

#profile-form .grayed-row {
  border-bottom: 1px solid #e6e6eb;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #f5f5fa;
}

#profile-form .row {
  border-bottom: 1px solid #e6e6eb;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 0px;
}

#profile-form .unit{
  font-weight: bold;
  padding-left: 7px;
}

.electron-switch{
  margin-left: 15px;
}

.electron-switch > span{
  margin-right: 5px;
  top:5px;
}

.electron-switch > div{
  top: 10px;
}

.successfully-logged-in{
  font-size: 24px;
  font-weight: bold;
}

.privacy-page{
  margin-top: 50px;
}

.privacy-page h3, .privacy-page h4{
  margin-bottom: 25px;
}

.moveUp {
  position: relative;
  bottom: 25px;
}

.forgot-password{
  margin-bottom: 20px;
}

#general-message{
  color: black;
}

#general-error{
  color: black;
  margin-bottom: 15px;
  margin-top: 15px;
}

ul.pagination{
  justify-content: center;
/*  color: #218838; */
}

.back-btn{
  margin-right: 10px;
}

/* ==================================================== */
 
.center-align {
  text-align: center;
}

/*
    Determine if .center-align class currently used in the following pages can be replaced with standard bootstrap:
      login,
      login-email,
      login-success,
      cancellation-success,
      password-recovery,
      password-recovery-second-step,
      payment-sucess (misspelled page),
      portal-redirect,
      privacy-text,
      sign-up,
      terms-and-services,
      terms-and-services-new,
      terms,
      text,
      upgrade,
      usa-cycling,
*/

/* ====================================================

    Remove the following classes if indeed they are no longer referenced anywhere (no sign of them in frontend files):
*/

/*

.reverse-contrast {
  background-color: #ffffff;
  }

.section-head {
  font-size: 2rem;
  font-weight: bold;
}

.logo-nav {
  height:50px;
  margin-bottom:7px;
}

.profile-image img {
  border-radius: 50%;
  width: 45px;
}

.content-section-a {
  padding: 30px 0px 30px 0px;
  margin-top: 30px;
  background-color: #f8f8f8;
}

.content-section-b {
  padding: 30px 0;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}

.no-padding {
  padding: 1rem 1rem !important;
}

.min-height-instructions {
  min-height: 90px;
}

.footer-copyright {
  margin-top: 50px;
  margin-bottom: 0px;
}

.footer-copyright .content-section-a {
  margin-bottom: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.footer-copyright .content-section-b {
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

*/

/* VIDEO PAGE */

.overlay-image-watched {
  width: 2.5rem!important;
  height: 2.5rem!important;
  z-index: 999999999!important;
  position: absolute;
  top: 40%;
  left: 40%;
  -webkit-transform: translate(-40%, -40%);
          transform: translate(-40%, -40%);
}

.line-clamp-5 {
  /* Added by Vee. */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-clamp-2 {
  /* Added by Vee. */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}


.video-container{
  position: relative;
  background-color: #000;
  height: 675px;
  width: 100%;
  padding: 0;
  margin: 0;
}


.video-container video{
  max-width: 100%;
  height: 675px;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
}

.not-theater-mode{
  height:50vw !important;
}

.tabber{
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
    z-index: 999999;
}

.video-time{
  margin: 0px 15px 0px 0;
}

.tracker-slope{
  margin: 0px 15px 0 0;
}

.tracker-slope-gradient {
  
  height: 25px;
  width: 25px;
  margin: 7.5px 15px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;


}

.disable {
  -webkit-filter: invert(0.5);
          filter: invert(0.5);
}

.tracker-slope-image {
  width: 100%;
  height: 100%;
  -webkit-filter: invert(1);
          filter: invert(1);
}

video.full-width-video-container {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  background: black;
}

.theater-video-container{
  position: relative;
  height: 100vh;

  /*-webkit-transition: width 2s, height 4s; /* For Safari 3.1 to 6.0 */
  /*transition: width 1s, height 1s;*/
}

.theater-video-container video{
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.title a{
  font-size:30px;
  text-decoration: none;
}

.form-error{
  color:red;
}

.form-label{
  color: #F91C3D;
}

.form-label{
  display: block;
}

.form-label .permalink-unique{
  color: lightgreen;
  margin-left: 10px;
  font-size: 20px;
}


.edit-button{
  color: gray;
  margin-left: 10px;
  font-size: 30px;
  float: right;
  margin-bottom: 15px;
/*  margin-top: 15px;*/
}

.delete-button{
  color: gray;
  margin-left: 10px;
  font-size: 30px;
  float: right;
  margin-bottom: 15px;
/*  margin-top: 15px;*/
}

.username{
  margin-top:10px;
  font-size:14px;
  text-align: center;
}

.chrome-extension-container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.record-buttons{
  display: flex;
  height: 45px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 15%;
  font-size: 30px;
  /*color: #FFFFFF;*/
  font-weight: bold;
  margin-left: 2%;
  text-align: center;
  min-width: 125px;
  width: 125px;
  font-family: Helvetica, Arial, sans-serif;
  text-shadow: black 1px 0px 0px;
  z-index: 999999;
  opacity: 1;
  color: #e55;
}

.gauges{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  top: 5%;
  font-size: 30px;
  /*color: #FFFFFF;*/
  font-weight: bold;
  margin-left: 2%;
  text-align: center;
  min-width: 125px;
  width: 125px;
  font-family: Helvetica, Arial, sans-serif;
  text-shadow: black 1px 0px 0px;
  z-index: 999999;
  opacity: 1;
  color: white;
}

.tracker-wrapper{
  position: relative;
  top: -17px;
}

.tracker{
  display: flex;
  height: 45px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 5%;
  font-size: 30px;
  font-weight: bold;
  margin-left: 2%;
  text-align: center;
  min-width: 20%;
  width: 96%;
  font-family: Helvetica, Arial, sans-serif;
  text-shadow: black 1px 0px 0px;
  z-index: 100;
  opacity: 1;
  color: white;
  white-space: nowrap;
}

.leaderboard{
  /*starts hidden*/
  border-radius: 6px;
  margin:0px;
  padding: 2px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  top: 2%;
  right: 1%;
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 600;
  text-align: center;
  width: 311px;
  font-family: Helvetica, Arial, sans-serif;
  /*text-shadow: black 1px 0px 0px;*/
  z-index: 9;
  opacity: 1;
}

.tracker-volume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px
}

.tracker .tracker-controls{
  flex: 0.1 1;
  display: flex;
  justify-content: flex-start;
  color: white;
}

.control-icon{
  padding-right: 20px;
}

.tracker .control-icon.disabled, .record-buttons .control-icon.disabled{
  color: #d3d3d3;
  cursor: default ;
}

.tracker .control-icon.disabled svg, .record-buttons .control-icon.disabled svg{
  cursor: default ;
}

.tracker .control-icon svg, .record-cuttons .control-icon svg{
  cursor: pointer;
}

.tracker .stop-icon-recording{
  padding-left: 20px;
}

.tracker .play-icon{
  width: 72px;
}

.tracker .pause-icon{
  width: 72px;
}


.record-buttons .record-icon{
  color:#7FFF00;
}

.record-buttons .record-icon-wait{
  color:#FFBE00
}


.record-buttons .stop-icon{

}

.stop-icon-recording{
  color: #F91C3D;
}


.tracker .tracker-controls .countdown-idle-timer{
  position: absolute;
  color: white;
  background: transparent;
  left: 25px;
  font-size: 27px;
  padding-top: 2px;
  padding-left: 1px;
}



.tracker .resume-icon{
  color:#7FFF00;
}


.indicators-container{
  flex: 0.8 1;
  display:flex;
  justify-content: space-around;
  padding-left: 115px;
  padding-right: 75px;
  color: #e55;
}

.tracker .heartrate{

}
.tracker .power{
}

.tracker .cadence{
}

.tracker .speed{
}

.ride-button {
  padding: 1rem;
}


.tracker-reset{
  position: absolute;
  left: 20px;
}

.tracker-red{
  color:#ff5;
}
.tracker-green{
  color:#f5f;
}
.tracker-white{
  color:#fff;
}



.video-wrapper{
  position: relative;
}

.video-overlay{
  width: auto;
  height: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  text-align: center;
  border-radius: 7.5px;
  color: white;
  z-index: 1000;

  /* Initially hidden */
  visibility: hidden;
  opacity: 0;
  -webkit-transition: visibility 0s linear 0.33s, opacity 0.33s linear;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;

}

.overlay-visible{
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}


/* Leaderboard */


#leaderboard a{
  font-size: 26px;
  color: #fff;
}

#leaderboard-list {
  text-align: left;
  margin: 0;
  padding: 2px;
  font-size: 13px;
}

#leaderboard-list li {
  margin:2px;
  padding: 2px;
  background-color: #eee;
  color: black;
  display: block;
  text-decoration: none;
  opacity: 0.70;
}

#leaderboard-list li.racer-node.active {
  background-color: #4CAF50;
  color: white;
}

#leaderboard-list li.racer-node.current {
  background-color: #8F8F8F;
}



#leaderboard-list .titles-node{
   text-align:center;
   right: 54px;
   width: 300px;
   height: 24px;
   background-color: white;
   margin-top: 0px;
   margin-bottom: 0px;
   font-weight:bold;
}

.titles-node table{
  table-layout: fixed;
  height: 30px;
  width: 296px;
}

#leaderboard-list .racer-node{
  text-align: center;
  right: 54px;
  width: 300px;
  height: 30px;
  margin-top: 0px;
  margin-bottom: 2px;
}

.racer-node table{
  table-layout: fixed;
  height: 30px;
  width: 296px;
  position: relative;
  top: -1px;
}

.titles-node .position-col{
  font-weight: bold;
  width: 31px;
  padding-right: 2px;
}

.titles-node .power-col{
  width: 50px;
  text-align: center;
  padding-right: 7px;
}

.titles-node .name-col{
  width: 110px;
  text-align: left;
  padding-right: 7px;
}

.titles-node .cadence-col{
  width: 30px;
  text-align: left;
  padding-right: 7px;
}



.racer-node .position-col{
  font-weight: bold;
  width: 31px;
  padding-right: 2px;
}

.racer-node .power-col{
  width: 50px;
  text-align: center;
  padding-right: 7px;
}

.racer-node .name-col{
  width: 110px;
  text-align: left;
  padding-right: 7px;
}

.racer-node .cadence-col{
  width: 30px;
  text-align: left;
  padding-right: 7px;
}



/* Transparent Item */

.transparent-item{
  width: 300px;
  height: 15px;
  background-color: transparent!important;
  margin-top: 0px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

.dot{
  height: 3px;
  width: 3px;
  background-color: white;
  border-radius: 50%;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
}

.vertical-line {
  border-left: 1px solid black;
  margin-right:15px;
  padding-top: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}


.fullscreen-toggle{
  position: absolute;
  right: 18px;
  top: 1px;
}

video::-webkit-media-controls {
  display: none;
}


@media all and (max-width: 768px) {

  video{
    width: 100%;
  }

  .video-container{
    width: 100%;
    position: relative;
  }

  /* Responsive Gauges */

  .gauges{
    height: 40%;
    bottom: 50%;
    font-size: 20px;
    margin-left: 2%;
    width: 75px;
    min-width: 75px;
    font-family: Helvetica, Arial, sans-serif;
    text-shadow: black 1px 0px 0px;
    z-index: 999999;
    opacity: 1;
    color: white;
  }


  /* Responsive Control Icons */

  .control-icon:first-child{
    padding-left: 5px;
  }

  .tracker{
    height: 35px;
    bottom: 5%;
    font-size: 25px;
    margin-left: 2%;
    min-width: 20%;
    width: 96%;
  }

  .record-buttons{
    height: 35px;
    bottom: 50px;
    font-size: 25px;
    margin-left: 2%;
    min-width: 90px;
    width: 90px;
  }

  .control-icon{
    padding-right: 15px;
  }

  .tracker .play-icon{
    width: 42px;
  }

  .tracker .pause-icon{
    width: 42px;
  }

  /* Responsive Video Time */

  .video-time{
    font-size: 25px;
    padding-top: 5px;
  }

  /* Responsive Leaderboard */

  .leaderboard{
    border-radius: 2px;
    margin:0px;
    top: 2%;
    right: 1%;
    font-size: 20px;
    width: 200px;
  }

  #leaderboard a{
    font-size: 17px;
    color: #fff;
  }

  #leaderboard-list {
    text-align: left;
    margin: 0;
    padding: 2px;
    font-size: 13px;
  }

  #leaderboard-list li {
    margin:2px;
    padding: 2px;
    background-color: #eee;
    color: black;
    display: block;
    text-decoration: none;
    opacity: 0.70;
  }

  #leaderboard-list li.racer-node.active {
    background-color: #4CAF50;
    color: white;
  }

  #leaderboard-list li.racer-node.current {
    background-color: #8F8F8F;
  }

  #leaderboard-list .titles-node{
     text-align:center;
     right: 54px;
     width: 300px;
     height: 24px;
     background-color: white;
     margin-top: 0px;
     margin-bottom: 0px;
     font-weight:bold;
     opacity:0.7;
  }

  .titles-node table{
    table-layout: fixed;
    height: 30px;
    width: 296px;
  }

  #leaderboard-list .racer-node{
    text-align: center;
    right: 54px;
    width: 300px;
    height: 30px;
    margin-top: 0px;
    margin-bottom: 2px;
    opacity:0.7;
  }

  .racer-node table{
    table-layout: fixed;
    height: 30px;
    width: 296px;
    position: relative;
    top: -1px;
  }

  .titles-node .position-col{
    font-weight: bold;
    width: 31px;
    padding-right: 2px;
  }

  .titles-node .power-col{
    width: 50px;
    text-align: center;
    padding-right: 7px;
  }

  .titles-node .name-col{
    width: 110px;
    text-align: left;
    padding-right: 7px;
  }

  .titles-node .cadence-col{
    width: 30px;
    text-align: left;
    padding-right: 7px;
  }



  .racer-node .position-col{
    font-weight: bold;
    width: 31px;
    padding-right: 2px;
  }

  .racer-node .power-col{
    width: 50px;
    text-align: center;
    padding-right: 7px;
  }

  .racer-node .name-col{
    width: 110px;
    text-align: left;
    padding-right: 7px;
  }

  .racer-node .cadence-col{
    width: 30px;
    text-align: left;
    padding-right: 7px;
  }



  /* Transparent Item */

  .transparent-item{
    width: 300px;
    height: 24px;
    background-color: transparent!important;
    margin-top: 0px;
    margin-bottom: 3px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: center;
    align-items: center;
  }

  .dot{
    height: 3px;
    width: 3px;
    background-color: white;
    border-radius: 50%;
    order: 0;
    flex: 0 1 auto;
    align-self: auto;
  }



}

/*.edit-box-holder{ 
}

.edit-box {
  float:right;
  margin-top:10px;
}
*/
/*.video-description{
  padding-top: 30px;
  text-align: center;
  border-color: #ddd;
}*/

/*.title-input{
  width:100%;
  height: 50px;
  margin-bottom: 20px;
  font-size: 25px;
  border-radius: 10px;
  padding: 10px;
}
*/
/*.video-title-edit-header {  
  color: black;
  padding-bottom: 5px;
}*/

/*.checkbox-row {
  width:980px;
  height: 50px;
  margin-top:0px;
}*/

/*.video-description-edit-header {
  color: black;
  padding-bottom: 5px;
}*/

/*.description-input{
  width:100%;
  height: 100px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}*/

.video-bottom{
  /*width: 960px;*/
  margin: 30px auto;
}

/*.btn.btn-primary.edit-save{
  background-color: #28a745;
  border:none;
}

.submit-button-edit {
  float:right;
}

.submit-column {
  position:relative;
}
*/
.image, .wrap {
    height: 100%;
    padding: 0;
    margin: 0;
}

.wrap{
  position: relative;
  background-color: #000;
}

.btn.race-button{
  margin-left: 25px;
  margin-bottom: 7px;
  background-color: #F91C3D;
  color: white;
}

.btn.race-button:hover{
  color: white;
}

.countdown-timer{
  font-size: 50px;
  color: #F91C3D;
  top: 30%;
  font-size: 150px;
  color: #F91C3D;
  position: absolute;
  width: 100%;
  text-align: center;
}

.img-mobile-thumb{
  width:100%;
}

.demo-sign{
  font-size: 30px;
  color: #F91C3D;
  position: absolute;
  top: 200px;
  left: 21px;
  z-index: 1;
}

.vjs-loading-spinner .vjs-control-text{
  display: none;
}


.full-height{
  height: 100vh;
}

.theater-mode{
  position: absolute;
  top:0;
}

.video-js.vjs-16-9 {
  padding-top: 0 !important;
}

.vjs-tech {
  object-fit: cover !important;
}

.vjs-poster{
  object-fit: cover !important;
}

.theater-mode .race-video-dimensions{
  width: 100vw !important;
  height: 100vh !important;
}

.overlay-title{
    text-align: center;
    padding: 0.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: .2rem;
    z-index: 999999;
    padding-bottom: 25px;
}

.button-container {

}

.mode-indicator {
  position: absolute;
  top: 12%;
  right: 1%;
  z-index: 2;
  font-size: 1.5vw;
}

.mode-indicator-electron {
  position: absolute;
  top: 70px; /* Vee: changed from 5% */
  right: 15px; /* Vee: changed from 2% */
  z-index: 2;
  font-size: 1.5vw;
}


.indicator-container-box {
  background: white;
  border-radius: 0.5rem;
  opacity: 0.85;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.indicator-container-box-electron {

}

.bottom-container {
  z-index: 999999!important;

}

.race-video-dimensions {
  height: auto!important;
}


.view-channel {
  position: absolute;
  right: 0;
  top: 25%;
}

.WR-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.WR-row {
  height: 30%;
  background-color: black;
}

.WR-col {

}

.WR-button-container {
  text-align: center;
  height: 15%;
}

.WR-bg {
  background: rgba(220, 220, 221, 0.8);
}

.WR-userlist {
  height: 70%;
  margin-top: 5%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1rem;
  position: relative;
}

.WR-userlist-title {
  height: 15%;
}

.WR-userlist-wrapper-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70%
}

.WR-userlist-wrapper {
  text-align: center;
  height: 70%
}

.WR-waiting-button-container {
  text-align: center;
}

.WR-spinnerbox {

}

.parent-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.recordbackground {
	width: 35px;
	height: 35px;
	font-size: 0;
	background-color: red;
	border: 0;
	border-radius: 35px;
	outline: none;
	position: absolute;
	z-index: 9999;
	margin-top: 18px; 
	margin-left: 18px;
}

.notRec{
	background-color: darkred;
}

.Rec{
	-webkit-animation-name: pulse;
	        animation-name: pulse;
	-webkit-animation-duration: 1.5s;
	        animation-duration: 1.5s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
}

@-webkit-keyframes pulse{
	0%{
		box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
	}
	65%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
	}
	90%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
	}
}

@keyframes pulse{
	0%{
		box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
	}
	65%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
	}
	90%{
		box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
	}
}
.progress {
  /*flex: 10;*/
  position: relative;
  margin: 14px 20px;
  display: flex;
  flex-basis: calc(100% - 650px);
  height: 14px;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
  background: rgba(0,0,0,0.5);
  cursor: pointer;
  opacity: 0.5;
}

.progress__filled {
  width: 50%;
  background: #028AC7;
  flex: 0 1;
  flex-basis: 0%;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trophy-button {
  height: 1.5rem;
  width: 1.5rem;
}

.trophy-button-sidebar {
  height: 1rem;
  width: 1rem;

}

.splash-top {
  padding-left: 0!important;
}

.splash-top-button {
  padding-right: 0!important;
}

.join-challenge-button {
  z-index: 999!important;
  position: absolute;
  top: 2%;
  right: 2%;
  text-align:center;
}

.join-challenge-card {
  z-index: 999!important;
  position: absolute;
  top: 2%!important;
  right: 2%!important;
  text-align:center;
}

.join-challenge-splash {
  z-index: 999!important;
  position: absolute;
  top: 2%!important;
  right: 7%!important;
  text-align:center;
}

.challenge-image-container {
  position: relative;
}

.title-container {
  font-size: 5rem;
  overflow: hidden;
}

.tab-list-container {
  list-style-type: none;
  padding-left: 0px;
}

.tab-list-li {
  float: left;
}

.headline-number-container{
  position: relative;
}

.headline-number-units {
  font-size: 1rem;
}

.icon-container {
  white-space: nowrap;
}

.icon-tab {
  display: inline-block;
}

.tab-sub-text {
  font-size: 0.6rem!important;
  margin-top: 0.2rem;
  text-align: center;
  font-weight: normal;
}

.tab-image-sidebar {
  height:1.75rem;
  width:1.75rem;
  margin: 0 auto;
}

.image-fine-print {
  width: 3.5vh;
  height: 3.5vh;
}

.registered {
  box-shadow: 0 0 0.4vh 0.4vh #C0E8F9;
}

.headline-challenge-image {
	height: 45vh;
}

.headline-challenge-description-box {
	position:relative;
}

.more-challenges-box {

}

.headline-challenge-btn-box {
	position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media screen and (max-width: 992px) {
  .sticky-column-activities {
    /* display: none; */
  }

  .participants-actual-text {
  	display: none;
  }
}

.challenge-sidebar-list {
  padding-top: 0rem!important;
  padding-bottom: 0rem!important;
  padding-left: 0.75rem!important;
  padding-right: 0.75rem!important;
}

.spinner-container {
  margin: 0 auto;
}

.scroll-down {
  margin-top: -6px;
  height: auto;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.parent {
  position: relative;
}

.description-box {
  display: flex;
}

.done {
  background: red;
}



/* SPLASH PAGE */

.splash-title-box {

}

.splash-more-info-big-box {

}

.splash-more-info-image-box {
  position: relative;
}

.splash-more-info-description-box {
  font-size: 20px;
}

.splash-bottom {

}

.splash-status {

}

.splash-button {
  text-align: center;
  position: absolute;
  right: 2%;
  top: 4%;
}

.splash-header-image {
  width: 100%;
  height: 100%;
}

.featured-challenge-mobile {
    display: none;
}

.featured-challenge-desktop {
  display: block;
}

@media only screen and (max-width: 600px) {
  .featured-challenge-desktop {
    display: none;
  }

  .featured-challenge-mobile {
    display: block;
  }
}

.show-hide-button {
  margin-top: 0!important;
  padding-top: 0!important;
}

.title-fine-print {
  overflow: hidden;
}

.truncate-container {
  overflow: hidden;
}

.challenge-sidebar-list-container {
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  -webkit-margin-after: 0.5rem!important;
          margin-block-end: 0.5rem!important;
}


.challenge-leaderboard-image-container {
  height: 2rem;
  width: 2rem;
}

.challenge-leaderboard-image {
  height: 100%;
  width: 100%;
  border-radius: 50%
}

.challenge-leaderboard-ranking {
  color: black;
  position: absolute;
  top: 20%;
  right: 1%;
  width: 2rem;
  height: 2rem;
  height: auto;

}

.challenge-leaderboard-medal {
  width: 100%;
  height: 100%;
}

.challenge-leaderboard-card {
  border-radius: 0px!important;
}

.challenge-leaderboard-col-left {
  padding-right: 5px!important;
}

.challenge-leaderboard-col-right {
  padding-left: 0!important
}

.nav-link.active {
  background-color: #28A745!important3
}

/*.fine-print-description-truncate {
  margin-bottom: 0.2rem!important;
}*/

input[type=checkbox] {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
}
.waveform {
	z-index: 9999;
}
.channel-image {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.channel-item-title{
	font-size:1.5em;
	text-decoration: none !important;
	margin-bottom:2px;
}

a.channel-item-title {color: #333;}
a.channel-item-title:hover {color: #aaa;}

.channel-item-info{
	font-size:0.9em;
	color: #333;
	margin-bottom:5px;
}

/*.channel-description{
	width: 100%;
	text-align: center;
	font-size: 20px;
}

.channel-edit-button{
	float: right;
	color: gray;
	font-size: 30px;
}
*/
.add-video-icon{
	float: left;
}

.form-group .channel-title-input{
  display: block;
	text-align: left;
	width: 100%;
  resize: none;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.form-group .channel-description-input{
	display: block;
	text-align: left;
	width: 100%;
  min-height: 80px;
	resize: yes;
	border: 1px solid #ced4da;
	border-radius: .25rem;
}

.channel-first-video{
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  cursor: default;
}


.channel-first-video img {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom:20px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(0px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}


.channel-thumb {
  object-fit: cover;
  float: left;
  height:225px;
  width:338px;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}

.channel-thumb .overlay {
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
}

.channel-thumb img {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom:20px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(0px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}


.productImage {
    position: relative;
    float: left;
    display: inline-block;
}
.productImage {
    width: 100%;
}
.productImage:after {
    padding-top: 70%;
    display: block;
    content:'';
}
.img-wrapper {
    padding: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.img-wrapper .thumb {
    height:100%;
}
.img-project, .thumb {
    display: block;

}
.img-project img, .thumb img {
    position:relative;
    top:50%;
    left:50%;
    max-width:100%;
    max-height:100%;
    -webkit-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
}
.productDetails {
}
/************************************************
Media queries
************************************************/
 @media all and (max-width: 1024px) {
    .img-project, .thumb {
        padding: 7px;
    }
}



body .search-input {
  margin-left: 20px;
  padding-left: 20px;
  /*display: inline-block;*/
}

.dropdown {
  display: inline-block;
  margin-left: 15px;
}

.gallery-ride {
  margin: 20px 0px;
}

.gallery-ride-channels {
  margin: 0px 0px;
}
.gallery-video-title {
 font-weight: normal;
/* font-size: calc(.25rem + 1vw); */
 font-size: min(max(1rem, 2vw), 16px);
}

.gallery-video-title-small {
 font-weight: normal;
/* font-size: calc(.25rem + 1vw); */
 font-size: min(max(1rem, 2vw), 13px);
}

.img-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  overflow: hidden;
}


.img-container VFImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-image{
  position: absolute !important;
  bottom: 10px !important;
  right: 10px !important;
  width: 30px !important;
  height: 30px !important;

}

.overlay-image:hover > videobox:hover {
  opacity: 1.0;
}

/* REMOVE ALL THE BELOW AS THEY APPEAR TO BE UNUSED: */

.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
  background: #000000;
}

.hovereffect .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 50px 20px;
}

.hovereffect img {
  display: block;
  position: relative;
  /* max-width: none; */
  /* width: calc(100% + 20px); */
  width: 100%;
  min-height: 200px;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-10px,0,0);
  transform: translate3d(0px,0,0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.hovereffect:hover img {
  opacity: 0.4;
  filter: alpha(opacity=40);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect div.text-overlay, .hovereffect h4 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  position: relative;
  font-size: 17px;
  overflow: hidden;
  padding: 0.5em 0;
  background: rgba(0, 0, 0, 0.6);
}

.hovereffect div.text-overlay{
  font-size:0.7em;
  background: rgba(0, 0, 0, 0.6);
  height:60px;
  border-radius: 4px;
  padding:4px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.625;
}

.hovereffect h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-100%,0,0);
  transform: translate3d(-100%,0,0);
}

.hovereffect:hover h2:after {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.hovereffect a, .hovereffect p {
  color: #FFF;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(100%,0,0);
  transform: translate3d(100%,0,0);
}

.hovereffect:hover a, .hovereffect:hover p {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* change name of time-indicator class below to distinguish it from one in app css */

.time-indicator {
  position: absolute;
  bottom: 22%;
  left: 12%;
  color: white;
  font-weight: bold;
}

.gradient-image {
  width: 100%;
  height: 100%;
}

.loading {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 0;
  &.reversed {
    li {
        border: 3px solid white;
        -webkit-animation: LOADINGREV 2s infinite;
                animation: LOADINGREV 2s infinite;
        &:nth-child(1n) {
          -webkit-animation-delay: 0s;
                  animation-delay: 0s;
        }
        &:nth-child(2n) {
          -webkit-animation-delay: 0.2s;
                  animation-delay: 0.2s;
        }
        &:nth-child(3n) {
          -webkit-animation-delay: 0.4s;
                  animation-delay: 0.4s;
        }
      }
  }
  li {
    height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    margin: 0;
    height: 10px;
    width: 10px;
    border: 3px solid $blue;
    border-radius: 100%;
    -webkit-transform: transformZ(0);
            transform: transformZ(0);
    -webkit-animation: LOADING 2s infinite;
            animation: LOADING 2s infinite;
    &:nth-child(1n) {
      left: -20px;
      -webkit-animation-delay: 0s;
              animation-delay: 0s;
    }
    &:nth-child(2n) {
      left: 0;
      -webkit-animation-delay: 0.2s;
              animation-delay: 0.2s;
    }
    &:nth-child(3n) {
      left: 20px;
      -webkit-animation-delay: 0.4s;
              animation-delay: 0.4s;
    }
  }
}


.card-image {
	width: 8em;
  border-radius: 49%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.headshot-container {
	margin-top: 20px;
}

.activities-card-container {
	margin-bottom: 20px;
}

.activities-title-container {
	margin-bottom: 10px;
	font-size: 150%;
}

.headline-number-text {
	font-size: 8vh;
	text-align: center;
}

.activities-participants {
	display: flex;
	position: relative;
}

.spinner-container {
	margin: 0 auto;
}

.scroll-down {
	margin-top: -6px;
  height: auto;
}

@media screen and (max-width: 992px) {
  .headline-challenge-description-box {
    display: none;
  }

  .participants-actual-text {
  	display: none;
  }
}

.truncate-parent {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap {
	white-space: nowrap;
}

.strava-upload-background {
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACXElEQVRIDbWWv08UURDHv/PgRBSKM6hQIBcLNfEHRmO8xhwJ/iDGH2gkmNigjaHARGOIP/4DOxqtjY2NhSTGaGMu8UxsTLA4tBARUNAjoBTEHLjjvLe6wu7b3dtLnGJvZt7M5/ve7OZ2CWKLR9G6JoU74uZA1KJzVRvztPTmy0sYXPcck/QHPiLgdNVQWyPznIjsVWbncfDaFKj3tg0TniPaoNlKKnLhVe4Kdfa5AunmuFL/ek7FzlxJyblBUKoOdOaaHxAdy/3UJ4g0OnQe1LzV1FDXZaAh2a2KF+i54W2A6htAJ694cSVOtED2NKht5yoOnRoA1q5flYsKIgVUz02vlx3H+CQjouP9Xj7OCRdo7wRtO2D6ufwTfP+Wx6Luq4Dc9EosVED1/gPyiwfg4SHw/FfDJHlc6cilSviwC+zIgnZ3GAAzgx8PActl8JO7HpTOXgdUjReHOVaBlbPHm2fA5Kgr9vQe9Li00eYMqOOC8aMutHyCOFCw75gQyE1PFIHSBNC2C5TZA2zcAh4fAeRkqJG/kPpG8R1w4RHwaymAqg1kdELv2m9aJNstwnLo6THg83uowxfBTa3A+FsrXCOsI/KzTby44IIkoO0HgXSLC5eYRwvWFp2sXMCAXrmgTRmo/V2uLyfBj5LrW66JBLAwC0y9MxgzGvGidq8LkwloYPGl7jNGpU/A7NTf0PqbWABzX4BvHw3MWSFmpUtSyeOm36HJrFgAfZ+Rp+lDdJ+w9Qny0VXBVZ4Zg/N6OLgQzOT//0tff1rI279dRvWwqnH5d61HLiz9RaHZvwH9D7PA1dYW7wAAAABJRU5ErkJggg==);
  position: relative;
  width: 100%;
  height: 100%;
}

.layer {
	background-color: rgba(248, 247, 216, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}






.events-image-container {
	height: auto;
	width: 1rem;
}

.events-image {
	height: 100%;
	width: 100%
}

.rounded {
	border-radius: 25%;
}
/* MOVED TO App.css

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.add-video-icon{
	margin-left: 20px;
}

*/
