.events-image-container {
	height: auto;
	width: 1rem;
}

.events-image {
	height: 100%;
	width: 100%
}

.rounded {
	border-radius: 25%;
}