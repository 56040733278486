.progress {
  /*flex: 10;*/
  position: relative;
  margin: 14px 20px;
  display: flex;
  flex-basis: calc(100% - 650px);
  height: 14px;
  transition: height 0.3s;
  background: rgba(0,0,0,0.5);
  cursor: pointer;
  opacity: 0.5;
}

.progress__filled {
  width: 50%;
  background: #028AC7;
  flex: 0;
  flex-basis: 0%;
}