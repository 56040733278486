.loading {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0;
  &.reversed {
    li {
        border: 3px solid white;
        animation: LOADINGREV 2s infinite;
        &:nth-child(1n) {
          animation-delay: 0s;
        }
        &:nth-child(2n) {
          animation-delay: 0.2s;
        }
        &:nth-child(3n) {
          animation-delay: 0.4s;
        }
      }
  }
  li {
    height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    width: 0;
    margin: 0;
    height: 10px;
    width: 10px;
    border: 3px solid $blue;
    border-radius: 100%;
    transform: transformZ(0);
    animation: LOADING 2s infinite;
    &:nth-child(1n) {
      left: -20px;
      animation-delay: 0s;
    }
    &:nth-child(2n) {
      left: 0;
      animation-delay: 0.2s;
    }
    &:nth-child(3n) {
      left: 20px;
      animation-delay: 0.4s;
    }
  }
}