

.card-image {
	width: 8em;
  border-radius: 49%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.headshot-container {
	margin-top: 20px;
}

.activities-card-container {
	margin-bottom: 20px;
}

.activities-title-container {
	margin-bottom: 10px;
	font-size: 150%;
}

.headline-number-text {
	font-size: 8vh;
	text-align: center;
}

.activities-participants {
	display: flex;
	position: relative;
}

.spinner-container {
	margin: 0 auto;
}

.scroll-down {
	margin-top: -6px;
  height: auto;
}

@media screen and (max-width: 992px) {
  .headline-challenge-description-box {
    display: none;
  }

  .participants-actual-text {
  	display: none;
  }
}

.truncate-parent {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap {
	white-space: nowrap;
}

.strava-upload-background {
	background: url('../images/strava.png');
  position: relative;
  width: 100%;
  height: 100%;
}

.layer {
	background-color: rgba(248, 247, 216, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}





